<template>
  <section class="finance-analytics scroll-content">
    <h2 class="md-headline" v-html="$translate('finance_analytics.title')" />

    <section v-if="data_loaded" class="admin-charts">
      <div class="admin-chart">
        <p class="md-title" v-html="$translate('finance_analytics.graph_titles.sales_in_days', {
          value: total_value_per_days,
          currency_sign: currency_sign
        })" />
        <canvas ref="day_limit_sales_chart"/>
        <div class="finance-analytics__actions">
          <md-button
            class="md-raised md-primary"
            :class="{ 'selected-button': chart_day_limit === 30 }"
            @click="chart_day_limit = 30"
          >
            {{ $translate("finance_analytics.chart_buttons.thirty_days") }}
          </md-button>
          <md-button
            class="md-raised md-primary"
            :class="{ 'selected-button': chart_day_limit === 14 }"
            @click="chart_day_limit = 14"
          >
            {{ $translate("finance_analytics.chart_buttons.fourteen_days") }}
          </md-button>
          <md-button
            class="md-raised md-primary"
            :class="{ 'selected-button': chart_day_limit === 7 }"
            @click="chart_day_limit = 7"
          >
            {{ $translate("finance_analytics.chart_buttons.seven_days") }}
          </md-button>
        </div>
      </div>

      <div class="admin-chart">
        <p class="md-title" v-html="$translate('finance_analytics.graph_titles.avg_order_value')" />
        <canvas ref="avg_order_value_chart"/>

        <p v-if="orders.length" class="md-subheading" :style="{ 'color': is_order_value_raising ? '#1f881f' : '#fc0015' }">
          {{ $translate(`finance_analytics.graph_labels.order_value_${is_order_value_raising ? "raising" : "falling"}`) }}
        </p>
      </div>

      <div class="admin-chart">
        <p class="md-title" v-html="$translate('finance_analytics.graph_titles.best_netting_variants')" />
        <div v-show="any_sold_products">
          <canvas ref="best_netting_variants"/>

          <div class="finance-analytics__actions">
            <md-button
              class="md-raised md-primary"
              :class="{ 'selected-button': variant_net_product_limit === 10 }"
              @click="variant_net_product_limit = 10"
            >
              {{ $translate("finance_analytics.chart_buttons.top_ten_products") }}
            </md-button>
            <md-button
              class="md-raised md-primary"
              :class="{ 'selected-button': variant_net_product_limit === 5 }"
              @click="variant_net_product_limit = 5"
            >
              {{ $translate("finance_analytics.chart_buttons.top_five_products") }}
            </md-button>
          </div>
          <p v-if="!any_sold_products" class="md-subheading" v-html="$translate('finance_analytics.no_sold_products')" />
        </div>
      </div>

      <div class="admin-chart admin-chart--doughnut">
        <p class="md-title" v-html="$translate('finance_analytics.graph_titles.sales_by_product')" />
        <div v-show="any_sold_products">
          <canvas ref="sales_by_product"/>
          <div class="finance-analytics__actions">
            <md-button
              class="md-raised md-primary"
              :class="{ 'selected-button': chart_product_limit === 10 }"
              @click="chart_product_limit = 10"
            >
              {{ $translate("finance_analytics.chart_buttons.top_ten_products") }}
            </md-button>
            <md-button
              class="md-raised md-primary"
              :class="{ 'selected-button': chart_product_limit === 5 }"
              @click="chart_product_limit = 5"
            >
              {{ $translate("finance_analytics.chart_buttons.top_five_products") }}
            </md-button>
          </div>
        </div>
        <p v-if="!any_sold_products" class="md-subheading" v-html="$translate('finance_analytics.no_sold_products')" />
      </div>

      <div class="admin-chart admin-chart--doughnut">
        <p class="md-title" v-html="$translate('finance_analytics.graph_titles.views_by_product')" />
        <div v-show="any_viewed_products">
          <canvas ref="views_by_product"/>
        </div>
        <p v-if="!any_viewed_products" class="md-subheading" v-html="$translate('finance_analytics.no_viewed_products')" />
        <div class="finance-analytics__actions">
          <md-button
            class="md-raised md-primary"
            :class="{ 'selected-button': show_views_on_store && !show_views_on_marketplace }"
            @click="show_views_on_store = true; show_views_on_marketplace = false"
          >
            {{ $translate("finance_analytics.chart_buttons.store_products") }}
          </md-button>
          <md-button
            class="md-raised md-primary"
            :class="{ 'selected-button': !show_views_on_store && show_views_on_marketplace }"
            @click="show_views_on_marketplace = true; show_views_on_store = false"
          >
            {{ $translate("finance_analytics.chart_buttons.marketplace_products") }}
          </md-button>
          <md-button
            class="md-raised md-primary"
            :class="{ 'selected-button': show_views_on_store && show_views_on_marketplace }"
            @click="show_views_on_marketplace = true; show_views_on_store = true"
          >
            {{ $translate("finance_analytics.chart_buttons.both_platforms") }}
          </md-button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import { currency_signs, order_financial_statuses, classic_date_format, dashed_date_format } from "../../../../Shared/constants/other"
import { CONFIGURATION_STORE, FINANCE_STORE, ADMIN, PRODUCTS_STORE, USER_STORE, default_option_name, CHARTJS_DOWNLOAD_URL } from "../../../constants/others_constants"
import { UPDATE_LOADER, FETCH_SHOPIFY_DATA, FETCH_AND_ASSING_MOMENT_LIBRARY } from "../../../stores/Admin/constants"
import { FETCH_IMPORTED_PRODUCTS } from "../../../stores/Admin/products/constants"
import { GET_USER_CURRENCY } from "../../../stores/Admin/user/constants"
import convert_currency from "../../../../Shared/methods/convert_currency"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import round_to_two_decimals from "../../../../Client/methods/round_to_two_decimals"
import init_chart from "../../../methods/init_chart"

const doughnut_graph_colors = {
  5: ["#0962FB", "#7DAFFB", "#C2DCFA", "#E4F3FA", "#F0FBFA"],
  10: ["#0962FB", "#2C79FB", "#4E90FB", "#7DAFFB", "#94BEFA", "#B6D5FA", "#C2DCFA", "#D9ECFA", "#E4F3FA", "#F0FBFA"]
}

export default {
  data() {
    return {
      chart_day_limit: 7,
      chart_product_limit: 5,
      variant_net_product_limit: 5,
      day_limit_sales_chart: null,
      sales_by_product: null,
      views_by_product: null,
      avg_order_value_chart: null,
      chart_loaded: false,
      data_loaded: false,
      show_views_on_store: true,
      show_views_on_marketplace: true,
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["rest_config"]),
    ...mapState(USER_STORE, ["rates_info", "user_info"]),
    ...mapGetters(USER_STORE, {
      user_currency: GET_USER_CURRENCY
    }),
    ...mapState(FINANCE_STORE, [
      "orders",
      "shopify_locations",
    ]),
    ...mapState(PRODUCTS_STORE, [
      "shopify_products",
      "imported_products"
    ]),
    ...mapState(ADMIN, ["$moment"]),
    currency_sign() {
      return currency_signs[this.user_currency]
    },
    // Total sales day-by-day in past X days
    total_orders_per_days() {
      if (!this.$moment || !this.rates_info) return

      const today = this.$moment(this.$moment().format())
      let orders_by_date = Array.from(new Array(this.chart_day_limit)).reduce(
        (total, _, index) => ({
          ...total,
          [this.$moment().subtract(index, "days").format(dashed_date_format)]: []
        }),
        {}
      )

      this.orders
        .filter(({ financial_status, created_at }) =>
          ![order_financial_statuses.refunded, order_financial_statuses.voided].includes(financial_status) &&
          today.diff(created_at, "days") < this.chart_day_limit
        )
        .forEach(({ total_price, created_at, currency }) => {
          const get_date = created_at.split("T")[0]
          orders_by_date[get_date] = [
            ...(orders_by_date[get_date] || []),
            {
              amount: this.user_currency === currency ? total_price : convert_currency(total_price, currency, this.rates_info.rates)
            }
          ]
        })

      return Object
        .keys(orders_by_date)
        .sort((a, b) => b - a) // Sort dates
        .reverse()
        .reduce((total, date) => ({ ...total, [date]: orders_by_date[date] }), {})
    },
    total_value_per_days() {
      return (Object.values(this.total_orders_per_days || {}).flat().reduce((total, { amount = 0 } = {}) => total + amount, 0) || 0).toFixed(2)
    },
    evolution_of_avg_order_value() {
      if (!this.$moment || !this.rates_info) return
      let orders_by_date = {}

      this.orders
        .filter(({ financial_status }) =>
          ![order_financial_statuses.refunded, order_financial_statuses.voided].includes(financial_status)
        )
        .forEach(({ total_price, created_at, currency }) => {
          const get_date = created_at.split("T")[0]
          orders_by_date[get_date] = [
            ...(orders_by_date[get_date] || []),
            this.user_currency === currency ? total_price : convert_currency(total_price, currency, this.rates_info.rates)
          ]
        })

      return Object
        .keys(orders_by_date)
        .reduce((total, date) => ([
          ...total,
          {
            date,
            value: orders_by_date[date].reduce((tot, value, index) => (tot + value) / (index + 1), 0)
          }
        ]), [])
        .sort(({ date }, { date: date_second }) => date.split("-").join("") - date_second.split("-").join("")) // Sort dates
    },
    is_order_value_raising() {
      const number_of_avg_order_data = this.evolution_of_avg_order_value.length
      const ten_percent = Math.ceil(number_of_avg_order_data * 0.1)
      const ninety_percent_orders = this.evolution_of_avg_order_value.slice(0, number_of_avg_order_data - ten_percent)
      const ten_percent_orders = this.evolution_of_avg_order_value.slice(number_of_avg_order_data - 1 - ten_percent, number_of_avg_order_data - 1)

      const avg_ninety_percent = ninety_percent_orders.reduce((tot, { value }) => tot + value, 0) / ninety_percent_orders.length
      const avg_ten_percent = ten_percent_orders.reduce((tot, { value }) => tot + value, 0) / ten_percent_orders.length

      return avg_ninety_percent < avg_ten_percent
    },
    all_products() {
      return [...(this.shopify_products || []), ...(this.imported_products || [])]
    },
    top_selling_products() {
      const all_products_with_sale_numbers = this.all_products.reduce((total, product) => {
        const total_sold = this.orders.reduce(
          (tot_quantity, { line_items }) => tot_quantity + (line_items.find(({ id }) => id === product.id) || { quantity: 0 }).quantity,
          0
        )
        return [
          ...total,
          {
            ...product,
            total_sold
          }
        ]
      }, [])

      return all_products_with_sale_numbers
        .sort(({ total_sold }, { total_sold: total_sold_second }) => total_sold_second - total_sold)
        .slice(0, this.chart_product_limit)
    },
    top_variants_by_net_profit() {
      return this.all_products
        .reduce((total, product) => {
          const total_net_profit_by_variants = this.orders.reduce(
            (tot_net_by_variant, { line_items, currency, customer_locale }) => {
              line_items.forEach(({ variant_id, quantity, price = 0, prices }) => {
                if (tot_net_by_variant[variant_id] !== undefined) {
                  const item_price = (prices || {})[customer_locale] ? (prices || {})[customer_locale].price : price
                  const net_price = convert_currency(item_price, currency, this.rates_info.rates) - tot_net_by_variant[variant_id].purchase_price
                  const new_net_total = round_to_two_decimals(tot_net_by_variant[variant_id].net_total + (net_price * quantity))

                  tot_net_by_variant[variant_id] = {
                    ...tot_net_by_variant[variant_id],
                    // Dont add values of orders when price is negative (purchase price > sale price)
                    net_total: new_net_total < 0 ? tot_net_by_variant[variant_id].net_total : new_net_total
                  }
                }
              })

              return tot_net_by_variant
            },
            product.variants.filter(({ purchase_price }) => purchase_price).reduce((tot, variant) => {
              return {
                ...tot,
                [variant.id]: {
                  net_total: 0,
                  translations: product.translations,
                  purchase_price: convert_currency(variant.purchase_price, product.purchase_currency, this.rates_info.rates),
                  option: variant.option1 === default_option_name ?
                    "" : [1,2,3].map(number => variant[`option${number}`]).filter(val => val).join(", ")
                }
              }
            }, {})
          )

          return [
            ...total,
            ...Object.values(total_net_profit_by_variants),
          ]
        }, [])
        .filter(({ net_total }) => net_total !== 0)
        .sort(({ net_total }, { net_total: net_total_second }) =>  net_total_second - net_total)
        .slice(0, this.variant_net_product_limit)
    },
    any_sold_products() {
      return this.orders.length
    },
    most_viewed_products() {
      return this.all_products
        .filter(({ views_on_marketplace, views_on_store }) =>
          (this.show_views_on_store && views_on_store) || (this.show_views_on_marketplace && views_on_marketplace)
        )
        .map(({ views_on_marketplace = 0, views_on_store = 0, ...rest }) => ({
          ...rest,
          views: (this.show_views_on_store ? views_on_store : 0) + (this.show_views_on_marketplace ? views_on_marketplace : 0)
        }))
        .sort(({ views }, { views: views_second }) => views_second - views)
        .slice(0, 10)
    },
    any_viewed_products() {
      return this.most_viewed_products.some(({ views }) => views > 0)
    },
  },
  watch: {
    total_orders_per_days(val) {
      if (val) this.init_sales_chart()
    },
    top_selling_products(val) {
      if (val) this.init_top_products_chart()
    },
    most_viewed_products() {
      this.init_top_viewed_products_chart()
    },
    evolution_of_avg_order_value(val) {
      if (val) this.init_avg_order_value_chart()
    },
    top_variants_by_net_profit(val) {
      if (val) this.init_best_netting_variants()
    },
  },
  async mounted() {
    this.update_loader(true)

    await this.fetch_and_assing_moment()

    if (!window.Chart) {
      await this.$loadScript(CHARTJS_DOWNLOAD_URL)
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (window.Chart) {
            clearInterval(interval)
            this.chart_loaded = true
            resolve()
          }
        }, 300);
      })
    } else this.chart_loaded = true

    if (!this.shopify_products) await this.fetch_shopify_data()
    if (!this.imported_products) await this.fetch_imported_data()

    this.data_loaded = true

    this.$nextTick(() => {
      this.init_sales_chart()
      this.init_top_products_chart()
      this.init_top_viewed_products_chart()
      this.init_avg_order_value_chart()
      this.init_best_netting_variants()
      this.update_loader(false)
    })
  },
  methods: {
    ...mapMutations(ADMIN, {
      update_loader: UPDATE_LOADER,
    }),
    ...mapActions(ADMIN, {
      fetch_shopify_data: FETCH_SHOPIFY_DATA,
      fetch_and_assing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY
    }),
    ...mapActions(PRODUCTS_STORE, {
      fetch_imported_data: FETCH_IMPORTED_PRODUCTS
    }),
    init_chart,
    init_top_products_chart() {
      // only show if there are any sales
      if (this.any_sold_products) this.init_chart(
        this.$refs.sales_by_product,
        this.top_selling_products.map(product => {
          const title = get_correct_translation_value(product, "title", [this.user_info.language_code])

          return title.length > 17 ? `${title.slice(0, 15)}..` : title
        }),
        this.top_selling_products.map(({ total_sold }) => total_sold),
        "sales_by_product",
        null,
        "doughnut",
        "#fff",
        doughnut_graph_colors[this.chart_product_limit]
      )
    },
    init_sales_chart() {
      this.init_chart(
        this.$refs.day_limit_sales_chart,
        Object
          .keys(this.total_orders_per_days)
          .map(date => this.$moment(date).format(classic_date_format)),
        Object
          .values(this.total_orders_per_days)
          .map(orders => orders ? orders.reduce((tot, { amount }) => tot + amount, 0) : 0),
        "day_limit_sales_chart",
        this.$translate("finance_analytics.graph_labels.sales_in_days", { currency: this.user_currency })
      )
    },
    init_avg_order_value_chart() {
      this.init_chart(
        this.$refs.avg_order_value_chart,
        this.evolution_of_avg_order_value.map(({ date }) => this.$moment(date).format(classic_date_format)),
        this.evolution_of_avg_order_value.map(({ value }) => value),
        "avg_order_value_chart",
        this.$translate("finance_analytics.graph_labels.avg_order_value", { currency: this.user_currency }),
        "line"
      )
    },
    init_best_netting_variants() {
      this.init_chart(
        this.$refs.best_netting_variants,
        this.top_variants_by_net_profit.map(variant => {
          const title = get_correct_translation_value(variant, "title", [this.user_info.language_code])
          const product_title = title.length > 17 ? `${title.slice(0, 15)}..` : title

          return product_title + (variant.option ? ` (${variant.option})` : "")
        }),
        this.top_variants_by_net_profit.map(({ net_total }) => net_total),
        "best_netting_variants",
        this.$translate("finance_analytics.graph_labels.net_profit", { currency: this.user_currency })
      )
    },
    init_top_viewed_products_chart() {
      if (this.most_viewed_products.length) {
        this.init_chart(
          this.$refs.views_by_product,
          this.most_viewed_products.map(product => {
            const title = get_correct_translation_value(product, "title", [this.user_info.language_code])

            return title.length > 17 ? `${title.slice(0, 15)}..` : title
          }),
          this.most_viewed_products.map(({ views }) => views),
          "views_by_product",
          null,
          "doughnut",
          "#fff",
          doughnut_graph_colors[10]
        )
      }
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin_charts.scss";
  @use "../../../../../styles/selected_button_styling.scss";

  .finance-analytics {
    &__actions {
      text-align: center;
      padding-top: $default-size;
    }
  }
</style>
