import {v4 as uuid} from "uuid"

export default ({
  title, entitled_collection_ids = [], entitled_product_ids = [],
  value, starts_at, ends_at, prerequisite_quantity_range,
  prerequisite_subtotal_range, is_percentage,
  created_at, usage_limit, id, translations
}) => ({
  allocation_method: "across",
  target_type: "line_item",
  target_selection: entitled_collection_ids.length || entitled_product_ids.length ? "entitled" : "all",
  allocation_method: "across",
  value_type: is_percentage ? "percentage" : "fixed_amount",
  customer_selection: "all",
  created_at,
  prerequisite_subtotal_range,
  title,
  value,
  translations,
  entitled_collection_ids,
  entitled_product_ids,
  entitled_variant_ids: [], // TODO allow selection of specific variants
  prerequisite_quantity_range,
  starts_at,
  ends_at,
  usage_limit,
  id: id || (created_at ? uuid() : undefined) // Dont set id & created_at if for shopify
})
