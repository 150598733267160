<template>
  <section class="orders scroll-content">
    <section v-if="opened_orders.length" class="orders__details">
      <md-button @click="deselect_orders"><arrow />{{ $translate("orders.view_to_all") }}</md-button>
      <hr/>
      <md-tabs>
        <template slot="md-tab" slot-scope="{ tab }">
          <div :class="{ 'orders__cross-order': tab.data.is_cross_order }">
            <md-icon v-if="tab.data.is_cross_order"><ulsemo_logo /></md-icon>
            <md-tooltip v-if="tab.data.is_cross_order" md-direction="right">{{ $translate("orders.table.cross_order") }}</md-tooltip>
            {{ orders ? (orders.find(({ id }) => id == tab.data.id) || {}).order_number : '' }}
          </div>
        </template>

        <md-tab
          v-for="order_id in opened_orders"
          :key="order_id"
          :md-label="orders ? (orders.find(({ id }) => id == order_id) || {}).order_number : ''"
          :md-template-data="{ id: order_id, is_cross_order: (orders ? (orders.find(({ id }) => id == order_id) || {}) : {}).is_cross_order  }"
        >
          <order_detail
            :order="orders ? (orders.find(({ id }) => id == order_id) || {}) : {}"
            :order_id="order_id"
            @initialize_order_edit="([val, order]) => { selected_orders = [order]; initialize_order_edit(val)}"
            @show_shipping_modal="([val, order]) => { selected_orders = [order]; show_shipping_modal = val}"
            @show_orders_info_message="([val, order]) => { selected_orders = [order]; show_orders_info_message = val }"
            @refund_selected_order="order => refund_selected_orders(order)"
            @download_order="download_order_pdf"
            @send_zasilkovna_package="send_zasilkovna_packages"
          />
        </md-tab>
      </md-tabs>
    </section>

    <section v-else-if="show_orders">
      <h3 class="md-display-1">
        {{ $translate("orders.title") }}:

        <md-button class="md-icon-button refresh-admin-data" @click="fetch_orders">
          <md-icon>cached</md-icon>
          <md-tooltip md-direction="top">{{ $translate("refresh") }}</md-tooltip>
        </md-button>
      </h3>

      <md-field class="orders__search" md-clearable>
        <label>{{ $translate("orders.search_label") }}</label>
        <md-input v-model="order_search_input" @input="search_orders"/>
        <span class="md-helper-text">{{ $translate("orders.search_message") }}</span>
      </md-field>

      <md-table
        v-model="final_orders"
        md-card
        @md-selected="orders => selected_orders = orders"
        :md-sort.sync="current_sort"
        :md-sort-order.sync="current_sort_order"
        :md-sort-fn="fetch_orders"
        :class="{ 'orders__loading': final_orders[0] && !final_orders[0].id}"
      >
        <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
          <div class="md-toolbar-section-start">{{ count }} {{ $translate("orders.orders_selected") }}</div>

          <div class="orders__functions">
            <md-button v-if="can_user_manage_financials" class="md-icon-button" @click="deselect_orders">
              <md-icon>unpublished</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.unselect_orders") }}</md-tooltip>
            </md-button>

            <md-button class="md-icon-button" @click="open_orders">
              <md-icon>visibility</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.view_order_details") }}</md-tooltip>
            </md-button>

            <md-button
              data-cy="close-order"
              v-if="can_user_manage_financials"
              class="md-icon-button"
              @click="update_selected_orders(order_action_types.close)"
            >
              <md-icon>delete_forever</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.close_orders") }}</md-tooltip>
            </md-button>

            <md-button
              data-cy="reopen-order"
              v-if="can_user_manage_financials"
              class="md-icon-button"
              @click="update_selected_orders(order_action_types.open)"
            >
              <md-icon>restore_from_trash</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.reopen_orders") }}</md-tooltip>
            </md-button>

            <md-speed-dial data-cy="manage-financials" v-if="can_user_manage_financials" md-event="click" md-direction="bottom">
              <md-speed-dial-target class="md-primary">
                <md-icon>more_horiz</md-icon>
              </md-speed-dial-target>

              <md-speed-dial-content>
                <md-button
                  data-cy="cancel-orders"
                  v-if="can_user_manage_financials"
                  class="md-icon-button"
                  @click="update_selected_orders(order_action_types.cancel)"
                >
                  <md-icon>close</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("orders.cancel_orders") }}</md-tooltip>
                </md-button>

                <md-button
                  data-cy="fulfill-orders"
                  class="md-icon-button"
                  @click="update_selected_orders(order_action_types.fulfill)"
                >
                  <md-icon>check_circle_outline</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("orders.fulfill_orders") }}</md-tooltip>
                </md-button>

                <md-button data-cy="edit-orders" class="md-icon-button" @click="initialize_order_edit(false)">
                  <md-icon>create</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("orders.edit_orders") }}</md-tooltip>
                </md-button>

                <md-button
                  :disabled="are_any_shopify_orders_selected || are_all_selected_orders_already_refunded"
                  class="md-icon-button"
                  @click="refund_selected_orders()"
                >
                  <refund_icon/>
                  <md-tooltip md-direction="top">
                    {{ $translate(`orders.${are_any_shopify_orders_selected || !are_all_orders_paypal_paid ? "only_paypal" : "refund_orders"}`) }}
                  </md-tooltip>
                </md-button>

                <md-button :disabled="are_any_shopify_orders_selected || are_all_selected_orders_already_paid_or_refunded" class="md-icon-button" @click="update_selected_orders(order_financial_statuses.paid, false)">
                  <md-icon>monetization_on</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("orders.mark_as_paid") }}</md-tooltip>
                </md-button>
  
                <md-button :disabled="are_any_ulsemo_orders_selected" class="md-icon-button" @click="show_orders_info_message = true">
                  <md-icon>info</md-icon>
                  <md-tooltip md-direction="top">{{ $translate(`orders.${are_any_ulsemo_orders_selected ? "only_for_shopify" : "other_options_info"}`) }}</md-tooltip>
                </md-button>
  
                <!-- TODO once integrated with a shipping provider, re-enable label creatment -->
                <md-button data-cy="shipping-labels" :disabled="are_any_shopify_orders_selected" class="md-icon-button" @click="show_shipping_modal = true">
                  <md-icon>request_quote</md-icon>
                  <md-tooltip md-direction="top">{{ $translate(`orders.${are_any_shopify_orders_selected ? "only_for_ulsemo" : "shipping_and_labels"}`) }}</md-tooltip>
                </md-button>

                <md-button v-if="!are_no_selected_zasilkovna_orders" :disabled="!zasilkovna_fulfillment_enabled" class="md-icon-button" @click="send_zasilkovna_packages">
                  <md-icon><img src="/static/icons/zasilkovna-logo-small.png" alt="zasilkovna"></md-icon>
                  <md-tooltip md-direction="top">{{ $translate(`orders.${zasilkovna_fulfillment_enabled ? "create_zasilkovna_packet" : "selected_orders_not_zasilkovna"}`) }}</md-tooltip>
                </md-button>
  
                <md-button v-if="are_no_selected_zasilkovna_orders" data-cy="update-order-id" :disabled="are_any_shopify_orders_selected" class="md-icon-button" @click="initialize_order_edit(true)">
                  <md-icon>local_shipping</md-icon>
                  <md-tooltip md-direction="top">{{ $translate(`orders.${are_any_shopify_orders_selected ? "only_for_ulsemo" : "update_tracking_ids"}`) }}</md-tooltip>
                </md-button>

                <md-button class="md-icon-button" @click="download_order_pdf(null)">
                  <md-icon>file_download</md-icon>
                  <md-tooltip md-direction="top">{{ $translate("orders.file_download") }}</md-tooltip>
                </md-button>
              </md-speed-dial-content>
            </md-speed-dial>
          </div>
        </md-table-toolbar>

        <md-table-row>
          <md-table-head
            v-for="key in table_keys"
            :key="`order-table-key-${key}`"
          >{{ $translate(`orders.table.${key}`) }}</md-table-head>
        </md-table-row>

        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          class="orders__list-item"
          md-selectable="multiple"
          md-auto-select
        >
          <md-table-cell
            v-for="key in table_keys"
            :key="`order-table-key-${key}`"
            :md-label="$translate(`orders.table.${key}`)"
            :md-sort-by="table_keys.includes(key) ? key : ''"
          >
            <div v-if="!item.id" class="element-loading" />
            <template v-else-if="key === 'products'">
              <div
                class="orders__product"
                v-for="product in item.line_items"
                :key="`order-${item.id}-${product.id}-${product.variant_id}`"
              >
                <div><b>{{ product.titleKey || product.title }}</b></div>
                <div v-if="product.variant_title || (product.variant_options && product.variant_options[0].value !== default_variant_title)">
                  <b>{{ $translate("orders.variant") }}: </b>{{
                    product.variant_title === undefined ? get_variant_title(product.variant_options) : product.variant_title
                  }}
                </div>
              </div>
            </template>
            <span
              data-cy="financial-status"
              v-else-if="key === 'financial_status'"
              class="orders__financial-status"
              :class="{
                'orders__financial-status--green': item[key] === 'paid',
                'orders__financial-status--orange': item[key] === 'pending'
              }"
            >{{ $translate(`orders.table.statuses.${item[key]}`) }}</span>
            <span
              data-cy="order-status"
              v-else-if="key === 'status'"
              class="orders__status"
            >{{ $translate(`orders.table.statuses.${item.closed_at ?
                order_statuses.closed : item.cancelled_at ?
                  order_statuses.cancelled : order_statuses.open}`) }}</span>
            <span
              data-cy="fulfillment-status"
              v-else-if="key === 'fulfillment_status'"
              class="orders__status"
            >{{ $translate(`orders.table.statuses.${item[key]}`) }}</span>
            <span
              data-cy="fulfillment-status"
              v-else-if="key === 'shipping_method' && item.shipping_lines"
              class="orders__status"
            >{{ item.shipping_lines[0].title || $translate(`orders.other_method_types.${item.shipping_lines[0].type}`) }}</span>
            <span
              v-else-if="key.includes('price')"
              class="orders__status"
            >
              <span v-if="is_currency_symbol(currency_signs[item.currency] || item.currency)">{{ currency_signs[item.currency] || item.currency }}</span>
              {{ item[key] }}
              <span v-if="!is_currency_symbol(currency_signs[item.currency] || item.currency)">{{ currency_signs[item.currency] || item.currency }}</span>
            </span>
            <span v-else :class="{ 'orders__cross-order': key === 'order_number' && item.is_cross_order }">
              <md-icon v-if="key === 'order_number' && item.is_cross_order"><ulsemo_logo /></md-icon>
              <md-tooltip v-if="key === 'order_number' && item.is_cross_order" md-direction="right">{{ $translate("orders.table.cross_order") }}</md-tooltip>
              {{ get_correct_value(item[key]) }}
            </span>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </section>

    <div v-if="!opened_orders.length && show_orders" class="orders__pagination">
      <md-field>
        <label for="page_limit">{{ $translate("orders.pagination.per_page") }}</label>
        <md-select v-model="page_limit" name="page_limit" id="page_limit">
          <md-option :value="20">20</md-option>
          <md-option :value="40">40</md-option>
          <md-option :value="60">60</md-option>
        </md-select>
      </md-field>
      <Pagination
        v-model="page"
        :records="number_of_orders"
        :per-page="page_limit"
        :hasRecords="false"
        :options="pagination_options"
        @paginate="update_page"
      />
    </div>

    <md-dialog :md-active.sync="show_orders_info_message">
      <md-dialog-title>{{ $translate("orders.other_options_info_title") }}</md-dialog-title>

      <p class="orders__dialog-message" v-html="$translate(`orders.other_options_info_message`)"/>

      <md-dialog-actions>
        <md-button class="md-primary" @click="show_orders_info_message = false">{{ $translate("close") }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="show_shipping_modal" class="orders__modal">
      <md-dialog-title data-cy="shipping-modal-title">{{ $translate("orders.shipping_modal.shipping_modal_title") }}</md-dialog-title>

      <md-dialog-content>
        <p class="md-body-1" data-cy="shipping-modal-text">{{ $translate("orders.shipping_modal.shipping_modal_message") }}</p>

        <div data-cy="shipping-payment-status">
          <div v-if="shipping_lines_of_selected_orders_needing_payment.length" class="md-triple-line">
            <p class="md-body-2">{{ $translate("orders.shipping_modal.paying_for_orders") }}:</p>
            <div
              v-for="({ order_number, id }) in shipping_lines_of_selected_orders_needing_payment"
              :key="id"
            >- {{ order_number }}</div>
            <md-divider />
          </div>
          <p v-else class="md-body-2">{{ $translate("orders.shipping_modal.no_unpaid") }}</p>
        </div>

        <payment_util
          v-if="shipping_lines_of_selected_orders_needing_payment.length"
          :payment_type="shipping_payments"
          :amount="shipping_price_of_selected_orders"
          :payment_info="shipping_payment_info_message"
          :success_message="$translate('orders.shipping_modal.payment_success_message')"
          @payment_completed="shipping_payment_completed"
        />
      </md-dialog-content>

      <md-dialog-actions>
        <md-button data-cy="close-shipping-modal" class="md-primary" @click="show_shipping_modal = false">{{ $translate("close") }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="show_edit_modal" class="orders__edit-order">
      <md-dialog-title>{{ $translate("orders.order_edit.title") }}</md-dialog-title>

      <md-dialog-content>
        <md-tabs>
          <md-tab
            v-for="(order, index) in selected_orders"
            :key="`${index}-selected-orders`"
            :md-label="`#${order.order_number}`"
            class="orders__edit-order-content"
          >
            <div v-if="order.shipping_lines && order.shipping_lines.length && show_tracking_edit_modal">
              <p>{{ $translate("orders.order_edit.tracking_numbers") }}:</p>
              <div
                v-for="({ title, rate_id }, index) in order.shipping_lines"
                :key="`${index}-shipping-option`"
              >
                - {{ title || "Unknown method" }}: 
                <md-field>
                  <md-input
                    data-cy="tracking-number"
                    :disabled="!can_user_manage_financials"
                    :placeholder="$translate('orders.tracking_number')"
                    :value="get_correct_fulfillment_tracking(order.id, rate_id)"
                    @change="(event) => update_fulfillment(event.target.value, order.id, title, rate_id, order.location_id, order.paypal_data)"
                  />
                </md-field>
              </div>
            </div>

            <p v-if="order.fulfillment_status === `${order_action_types.fulfill}ed` && !show_tracking_edit_modal" class="md-subheading">
              {{ $translate("orders.order_edit.can_not_edit_fulfilled") }}
            </p>

            <div v-if="!show_tracking_edit_modal" class="orders__edit-order-address">
              <p>{{ $translate('orders.order_edit.shipping_address') }}</p>
              <div v-for="key in order_edit_keys" :key="`order-shipping-edit-${key}`">
                <md-field>
                  <md-input
                    :placeholder="$translate(`address.${key}`)"
                    :value="get_value(key, order, 'shipping_address')"
                    @change="(event) => update_order_data(`shipping_address.${key}`, event.target.value, order.id)"
                    :disabled="order.fulfillment_status === `${order_action_types.fulfill}ed` || !can_user_manage_financials"
                  />
                </md-field>
              </div>
            </div>
            <div v-if="!show_tracking_edit_modal" class="orders__edit-order-address">
              <p>{{ $translate('orders.order_edit.billing_address') }}</p>
              <div v-for="key in order_edit_keys" :key="`order-billing-edit-${key}`">
                <md-field>
                  <md-input
                    :placeholder="$translate(`address.${key}`)"
                    :value="get_value(key, order, 'billing_address')"
                    @change="(event) => update_order_data(`billing_address.${key}`, event.target.value, order.id)"
                    :disabled="order.fulfillment_status === `${order_action_types.fulfill}ed` || !can_user_manage_financials"
                  />
                </md-field>
              </div>
            </div>
          </md-tab>
        </md-tabs>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="cancel_order_edit(show_tracking_edit_modal)">{{ $translate("cancel") }}</md-button>
        <md-button
          data-cy="save-order"
          class="md-primary"
          @click="show_tracking_edit_modal ? update_selected_orders(order_action_types.ship) : save_order_edit()"
        >{{ $translate("save") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import convertor from "convert-units"
import Pagination from "vue-pagination-2"
import refund_icon from "../../../../Shared/components/icon_components/refund-icon"
import payment_util from "../payment_util"
import { CONFIGURATION_STORE, FINANCE_STORE, ADMIN, shopify, imported, PRODUCTS_STORE } from "../../../constants/others_constants"
import {
  UPDATE_LOADER, FETCH_SHOPIFY_FINANCIALS,
  FETCH_IMPORTED_FINANCIALS, ADD_GLOBAL_ERROR, FETCH_SHOPIFY_DATA, POST_UPDATED_ORDERS, FETCH_AND_ASSING_MOMENT_LIBRARY
} from "../../../stores/Admin/constants"
import {
  default_image_src, date_time_format, order_action_types, orders_API_date_format, currency_signs, order_financial_statuses, other_shipping_methods
} from "../../../../Shared/constants/other"
import {
  UPDATE_SHOPIFY_ORDERS,
  UPDATE_IMPORTED_ORDERS,
  REFUND_ULSEMO_ORDER,
  RESTOCK_PRODUCT,
  GET_ORDERS
} from "../../../stores/Admin/finance/constants"
import fulfillment_statuses from "../../../constants/fulfillment_statuses"
import { shipping_payments } from "../../../constants/admin_payment_types"
import un_bind from "../../../../Shared/methods/un_bind"
import { create_zasilkovna_packets, purchase_shipping_label } from "../../../constants/endpoints/other"
import { FETCH_IMPORTED_PRODUCTS } from "../../../stores/Admin/products/constants"
import is_currency_symbol from "../../../../Shared/methods/is_currency_symbol"
import order_detail from "./order_detail"
import debounce from "../../../../Shared/methods/debounce"
import { MIN_SEARCH_CHARACTERS } from "../../../../Marketplace/constants/other"
import arrow from "../../../../Shared/components/icon_components/arrow"
import ulsemo_logo from "../../../../Shared/components/icon_components/ulsemo-icon"
import order_statuses from "../../../constants/order_statuses"
import download_order from "../../../../Shared/methods/download_order"
import { default_variant_title } from "../../../../../data/other_constants"
import { order_fulfillment_statuses } from "../../../../../../functions/constans/other"

const default_states = {
  show_orders_info_message: false,
  show_orders_edit_modal: false,
  show_shipping_modal: false,
  show_tracking_edit_modal: false,
}

export default {
  components: {
    ulsemo_logo,
    refund_icon,
    payment_util,
    order_detail,
    arrow,
    Pagination
  },
  props: {
    can_user_manage_financials: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      ...default_states,
      show_orders: false,
      deselecting_orders: false,
      order_search_input: "",
      current_sort: "created_at",
      current_sort_order: "desc",
      selected_orders: [],
      found_orders: [],
      page: 1,
      page_limit: 20,
      comparable_keys: [
        "order_number",
        "status",
        "financial_status",
        "total_price",
        "fulfillment_status",
        "created_at",
      ],
      table_keys: [
        "order_number",
        "status",
        "financial_status",
        "fulfillment_status",
        "shipping_method",
        "total_price",
        "created_at",
        "contact_email",
        "products"
      ],
      order_edit_keys: [
        "address1",
        "address2",
        "city",
        "country",
        "first_name",
        "last_name",
        "zip",
      ],
      fulfillments: {},
      opened_orders: [],
      pagination_options: {
        edgeNavigation: false,
        texts: {
          count: `${
            this.$translate('orders.pagination.multiple')}|${
            this.$translate('orders.pagination.some')}|${
            this.$translate('orders.pagination.one')
          }`
        }
      },
      shopify,
      imported,
      default_image_src,
      order_action_types,
      shipping_payments,
      currency_signs,
      order_statuses,
      default_variant_title,
      order_financial_statuses
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["rest_config", "shipping_options", "project_config"]),
    ...mapState(FINANCE_STORE, [
      "orders",
      "number_of_orders",
      "number_of_order_pages",
    ]),
    ...mapState(ADMIN, ["$moment"]),
    final_orders: {
      get() { return this.orders || [{},{},{},{}] },
      set() {}
    },
    show_edit_modal: {
      get() { return this.show_tracking_edit_modal || this.show_orders_edit_modal },
      set() {
        this.show_tracking_edit_modal = false
        this.show_orders_edit_modal = false
      }
    },
    zasilkovna_fulfillment_enabled() {
      return this.selected_orders?.every(
        ({
          shipping_lines = [],
          fulfillment_status
        }) =>
          shipping_lines?.[0]?.type === other_shipping_methods.zasilkovna &&
          fulfillment_status !== order_fulfillment_statuses.zasilkovna
      )
    },
    are_no_selected_zasilkovna_orders() {
      return this.selected_orders?.every(({ shipping_lines = [] }) => !shipping_lines?.[0]?.type)
    },
    shipping_lines_of_selected_orders_needing_payment() {
      return this.selected_orders
        .flatMap(({ shipping_lines, order_number, order_id, paypal_data }) =>
          shipping_lines ? shipping_lines.map(line => ({ ...line, order_number, order_id, paypal_data })) : []
        )
        .filter(({ shipment_id, paid }) => shipment_id && !paid)
    },
    shipping_price_of_selected_orders() {
      return this.shipping_lines_of_selected_orders_needing_payment.reduce(
        (total_price, { initial_price }) => total_price + Number(initial_price),
        0
      )
    },
    shipping_payment_info_message() {
      const rate_ids = this.shipping_lines_of_selected_orders_needing_payment.reduce(
        (final_ids, { rate_id }) => final_ids + `${rate_id},`,
        ""
      )

      return this.$translate("orders.shipping_modal.payment_info_message", { rate_ids })
    },
    are_all_orders_paypal_paid() {
      return this.selected_orders.length ? this.selected_orders.some(({ paypal_data }) => paypal_data) : false
    },
    are_any_shopify_orders_selected() {
      return this.selected_orders.length ? this.selected_orders.some(
        ({ payment_method, paypal_data }) => !payment_method && !paypal_data
      ) : false
    },
    are_any_ulsemo_orders_selected() {
      return this.selected_orders.length ? this.selected_orders.some(
        ({ payment_method, paypal_data }) => payment_method || paypal_data
      ) : false
    },
    are_all_selected_orders_already_refunded() {
      return this.selected_orders.length ? this.selected_orders.every(
        ({ financial_status }) => financial_status === order_financial_statuses.refunded
      ) : false
    },
    are_all_selected_orders_already_paid_or_refunded() {
      return this.selected_orders.length ? this.selected_orders.every(
        ({ financial_status }) => financial_status === order_financial_statuses.paid || financial_status === order_financial_statuses.refunded
      ) : false
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.opened_orders) this.deselect_orders()
      else this.select_opened_orders()
    },
    page_limit() {
      this.fetch_orders()
    }
  },
  async mounted() {
    this.update_loader(true)

    await this.fetch_and_assing_moment()

    this.update_loader(false)
    this.select_opened_orders()

    this.show_orders = true
  },
  methods: {
    ...mapActions(FINANCE_STORE, {
      update_shopify_orders: UPDATE_SHOPIFY_ORDERS,
      update_imported_orders: UPDATE_IMPORTED_ORDERS,
      refund_order: REFUND_ULSEMO_ORDER,
      restock_product: RESTOCK_PRODUCT,
      post_updated_orders: POST_UPDATED_ORDERS,
      get_orders: GET_ORDERS
    }),
    ...mapMutations(ADMIN, {
      update_loader: UPDATE_LOADER,
      add_global_error: ADD_GLOBAL_ERROR
    }),
    ...mapActions(ADMIN, {
      fetch_and_assing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY,
      fetch_shopify_financials: FETCH_SHOPIFY_FINANCIALS,
      fetch_imported_financials: FETCH_IMPORTED_FINANCIALS,
      fetch_shopify_data: FETCH_SHOPIFY_DATA
    }),
    ...mapActions(PRODUCTS_STORE, {
      fetch_imported_data: FETCH_IMPORTED_PRODUCTS
    }),
    is_currency_symbol,
    async send_zasilkovna_packages(order, order_type) {
      this.update_loader()
      try {
        if (this.shipping_options?.other_methods?.zasilkovna?.api_password) {
          const { data } = await create_zasilkovna_packets({
            api_password: this.shipping_options.other_methods.zasilkovna.api_password,
            packets: order_type ? // If sending single order
              [{
                order_number: order.order_number,
                name: order.shipping_address.first_name,
                last_name: order.shipping_address.last_name,
                email: order.shipping_address.email,
                phone: order.shipping_address.phone,
                location_id: order.shipping_lines[0].id,
                price: order.subtotal_price,
                store_domain: this.project_config.domain,
                weight: order.line_items.reduce(
                  (tot, { weight, weight_unit }) => tot + convertor(Number(weight)).from(weight_unit).to("kg"), 0
                )
              }] : this.selected_orders.map(selected_order => ({
                order_number: selected_order.order_number,
                name: selected_order.shipping_address.first_name,
                last_name: selected_order.shipping_address.last_name,
                email: selected_order.shipping_address.email,
                phone: selected_order.shipping_address.phone,
                location_id: selected_order.shipping_lines[0].id,
                price: selected_order.subtotal_price,
                store_domain: this.project_config.domain,
                weight: selected_order.line_items.reduce(
                  (tot, { weight, weight_unit }) => tot + convertor(Number(weight)).from(weight_unit).to("kg"), 0
                )
              }))
          })

          const file = Uint8Array.from(
            window.atob(
              data["SOAP-ENV:Envelope"]["SOAP-ENV:Body"]["ns1:packetsLabelsPdfResponse"].packetsLabelsPdfResult._text
            ), c => c.charCodeAt(0)
          )
          const blob = new Blob([file], { type: "octet/stream" })
          const link = document.createElement("a")

          link.setAttribute("download", "zasilkovna-order-labels.pdf")
          link.href = window.URL.createObjectURL(blob)
          link.click()

          if (order_type) {
            await this.post_updated_orders([[order], this.fulfillments, order_action_types.zasilkovna, order_type])
            await this.fetch_orders()
          } else await this.update_selected_orders(order_action_types.zasilkovna, false, order)
        }
      } catch ({ message }) {
        this.add_global_error({ message })
      }
      this.update_loader(false)
    },
    update_page(page) {
      this.page = page
      this.fetch_orders()
    },
    reset_state() {
      Object.keys(default_states).forEach(key => this[key] = false)
    },
    async update_selected_orders(action_type, update_shopify_orders = true) {
      let orders = this.selected_orders

      // If marking as paid, make sure orders are not already paid or refunded
      if (action_type === order_financial_statuses.paid) {
        orders = orders.filter(
          ({ financial_status }) =>
            financial_status !== order_financial_statuses.paid &&
            financial_status !== order_financial_statuses.refunded
        )
      }
      // Ulsmeo orders have paypal_data parameter
      const ulsemo_orders = orders.filter(({ payment_method, paypal_data }) => payment_method || paypal_data)
      const shopify_orders = orders.filter(({ payment_method, paypal_data }) => !payment_method && !paypal_data)

      await Promise.all([
        ulsemo_orders && this.post_updated_orders([ulsemo_orders, this.fulfillments, action_type, imported]),
        update_shopify_orders && shopify_orders && this.post_updated_orders([shopify_orders, this.fulfillments, action_type, shopify])
      ])
      this.reset_state()
      await this.fetch_orders()
    },
    async fetch_orders() {
      if (this.deselecting_orders) this.deselecting_orders = false
      else await this.get_orders([
        this.page_limit,
        this.page - 1, // turn into array index
        this.order_search_input,
        Object.keys(order_statuses).reduce((tot, key) => ({
          ...tot,
          [key]: this.$translate(`orders.table.statuses.${key}`)
        }), {}),
        this.current_sort,
        this.current_sort_order
      ])
    },
    select_opened_orders() {
      if (this.$route.query.opened_orders) {
        this.opened_orders = this.$route.query.opened_orders.split(",")
      }
    },
    search_orders() {
      debounce(async () => {
        if (
          this.order_search_input.length > MIN_SEARCH_CHARACTERS ||
          !this.order_search_input.length
        ) await this.fetch_orders()
      }, 1000, this)()
    },
    open_orders() {
      if (!this.selected_orders.length) return

      this.opened_orders = this.selected_orders.map(({ id }) => id)
      this.$router.push({
        route: "/admin/finance/orders",
        query: { opened_orders: this.opened_orders.toString() }
      })
    },
    deselect_orders() {
      // When going from order detail without previous orders load
      if (this.selected_orders.length === 0) this.fetch_orders()

      this.show_orders = false
      this.deselecting_orders = true
      this.selected_orders = []
      this.opened_orders = []

      if (this.$route.query.opened_orders) this.$router.push({ route: "/admin/finance/orders" })

      this.$nextTick(() => this.show_orders = true)
    },
    async shipping_payment_completed() {
      this.update_loader()
      try {
        const labels = await Promise.all(
          this.shipping_lines_of_selected_orders_needing_payment.map(
            async ({ rate_id, shipment_id }) => await purchase_shipping_label({ rate_id, shipment_id }) // TODO Finalize shipping label purchase and order state update
          )
        )
        // TODO change from download lib to something eles: await Promise.all(labels.map(async ({ label_url }) => await download(label_url)))
        labels.forEach(({ rate_id, tracking_number }) => {
          const { order_id, title, paypal_data } = this.shipping_lines_of_selected_orders_needing_payment.find(
            ({ rate_id: found_rate }) => found_rate === rate_id
          ) || {}
          const index_of_shipping_line = 0

          if (order_id) {
            this.update_order_data(`shipping_lines.${index_of_shipping_line}.paid`, true, order_id)
            this.update_fulfillment(tracking_number, order_id, title, rate_id, null, paypal_data)
          }
        })

        this.add_global_error({
          message: this.$translate("orders.shipping_modal.shipping_paid"),
          severity: severities.SUCCESS
        })
        this.$emit("payment_completed")
      } catch ({ message }) {
        this.add_global_error({ message })
      }
      this.update_loader(false)
    },
    get_value(keys, order, initial_key) {
      return [initial_key, ...keys.split(".")].reduce((rest_obj, key) => rest_obj[key], order)
    },
    initialize_order_edit(editing_tracking) {
      this.fulfillments = this.selected_orders.reduce((tot, { fulfillments = [], id }) => ({
        ...tot,
        [id]: fulfillments
      }), {})
      this[`show_${editing_tracking ? "tracking" : "orders"}_edit_modal`] = true
    },
    get_formated_date(unformatted_date) {
      return this.$moment(unformatted_date, orders_API_date_format).format(date_time_format)
    },
    get_correct_value(val) {
      if (val === null) return "undefined"
      if (!isNaN(val)) return Number(val)
      if (this.$moment(val, orders_API_date_format, true).isValid()) return this.get_formated_date(val)

      return val
    },
    get_variant_title(options) {
      return options.reduce((title, { value }, index) => `${title} ${index ? "/" : ""} ${value}`, "")
    },
    get_correct_fulfillment_tracking(order_id, fulfillment_id) {
      return ((this.fulfillments[order_id] || []).find(({ id }) => fulfillment_id === id) || {}).tracking_number || ""
    },
    update_fulfillment(tracking_number, order_id, shipping_code, rate_id, location_id = null, paypal_data) {
      // Use location ID if shopify order
      const correct_location_id = this.get_location_id(location_id, paypal_data)
      const fulfillment_index = this.fulfillments[order_id].findIndex(({ id }) => id === rate_id)

      if (fulfillment_index > -1) this.fulfillments[order_id][fulfillment_index] = {
        ...this.fulfillments[order_id][fulfillment_index],
        tracking_number,
        updated_at: this.$moment().format()
      }

      else this.fulfillments[order_id] = [
        ...this.fulfillments[order_id],
        {
          id: rate_id,
          order_id,
          status: fulfillment_statuses[tracking_number ? "pending" : "open"],
          tracking_company: shipping_code,
          created_at: this.$moment().format(),
          tracking_number,
          updated_at: this.$moment().format(),
          location_id: correct_location_id
        }
      ]
    },
    update_order_data(route, value, id) {
      const selected_orders = un_bind(this.selected_orders)

      this.selected_orders = selected_orders.map(order => {
        if (order.id === id) {
          const route_arr = route.split(".")
          route_arr.reduce((tot, key, ind) => ind === route_arr.length - 1 ? tot[key] = value : tot[key], order)
        }

        return order
      })
    },
    cancel_order_edit(editing_tracking) {
      this.fulfillments = {}
      this[`show_${editing_tracking ? "tracking" : "orders"}_edit_modal`] = false
    },
    async save_order_edit() {
      // Ulsmeo orders have paypal_data parameter
      const ulsemo_orders = this.selected_orders.filter(({ paypal_data }) => paypal_data)
      const shopify_orders = this.selected_orders.filter(({ paypal_data }) => !paypal_data && Object.keys(this.fulfillments).length)

      await Promise.all([
        ulsemo_orders && this.post_updated_orders([ulsemo_orders, this.fulfillments, order_action_types.edit, imported]),
        shopify_orders && this.post_updated_orders([shopify_orders, this.fulfillments, order_action_types.fulfill, shopify])
      ])
      this.reset_state()
      await this.fetch_orders()

      this.cancel_order_edit()
    },
    get_location_id(location_id = null, paypal_data) {
      // Only Ulsemo orders have paypal_data
      return !location_id && !paypal_data ? (this.shopify_locations[0] || {}).id : location_id
    },
    async refund_selected_orders(order) {
      if (order && order.financial_status === order_financial_statuses.refunded) await this.refund_order([{
        ...order.paypal_data,
        order_currency: order.currency,
        cross_selling_store_id: order.cross_selling_store_id,
        cross_product_profit_share: order.cross_product_profit_share,
        order_number: order.order_number,
        order_id: order.id,
        customer_locale: order.customer_locale,
        payment_method: order.payment_method
      }, {}])
      else {
        const promises = this.selected_orders.map(
          ({
            paypal_data, id, customer_locale, currency, cross_selling_store_id,
            cross_product_profit_share, order_number, financial_status, payment_method
          }) => financial_status === order_financial_statuses.refunded ?
            null :
            this.refund_order([{
            ...paypal_data,
            order_id: id,
            order_currency: currency,
            cross_selling_store_id,
            cross_product_profit_share,
            order_number,
            customer_locale,
            payment_method
          }, {}])
        )

        await Promise.all(promises)
      }

      this.reset_state()
      await this.fetch_orders()

      this.cancel_order_edit()
    },
    download_order_pdf(order_id) {
      if (order_id) download_order(
        this.orders.find(({ id }) => order_id === id),
        this.$translate,
        this.$moment,
        this.shipping_options,
        this.rest_config
      )
      else this.selected_orders.forEach(order => download_order(
        order, this.$translate, this.$moment, this.shipping_options, this.rest_config
      ))
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/element_loading.scss";

  .orders {
    position: relative;

    &__pagination {
      position: sticky;
      display: flex;
      justify-content: space-between;
      bottom: -20px;
      left: 17px;
      width: calc(100% - 33px);
      z-index: 0;
      background: $pure-white;
      padding: $half-default-size $default-size;
      box-shadow: 0px -3px 13px -6px $shadow-color;

      .md-field {
        width: 200px;
      }

      @media (max-width: $tablet) {
        width: 100%;
        padding: $half-default-size;

        .md-field {
          margin-bottom: 0;
          width: 50%;
        }
      }

      @media (max-width: $mobile--large) {
        display: grid;
        grid-template-columns: 1fr;

        .md-field {
          margin-bottom: $half-default-size;
          width: 100%;
        }
      }
    }

    &__cross-order {
      white-space: nowrap;

      i {
        width: 15px !important;
        height: 15px !important;
        min-width: 15px !important;
        min-height: 15px !important;
        margin: 0 5px !important;
      }
    }

    &__list-item {
      position: relative;
      cursor: pointer;
    }

    &__details {
      margin-top: $default-size;

      hr {
        margin: 0;
      }

      & > .md-button {
        margin: 0 0 $half-default-size;

        svg {
          display: inline-block;
          vertical-align: middle;
          transform: rotate(180deg);
          width: 20px;
          height: 20px;
          margin-right: $default-size;
        }
      }

      .md-tabs-navigation {
        overflow: auto;
      }

      .md-tabs-content {
        height: auto !important;
      }
    }

    &__search {
      width: 50% !important;
      min-width: 400px;
      max-width: 700px;
      margin: 0 $default-size 50px !important;

      @media (max-width: $tablet--small) {
        min-width: 100%;
        margin: 0 0 70px !important;
      }
    }

    &__loading {
      .md-checkbox .md-checkbox-container {
        display: none;
      }
    }

    .element-loading {
      height: 20px;
    }

    .md-toolbar {
      min-height: 57px;
    }

    .md-table-content {
      overflow: auto !important;

      @media (max-width: $tablet) {
        padding: 0 $default-size !important;
      }
    }

    .VuePagination {
      display: flex;
      align-items: center;
      text-align: right;

      @media (max-width: $tablet--small) {
        overflow: auto;
      }

      p {
        margin: 0;
        font-weight: bold;
      }

      ul {
        display: flex;
        padding: 0;
        list-style-type: none;
        margin-top: 0px;

        li {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: none;
          }
        }
      }

      &__pagination-item {
        margin: 0 5px;
        padding: 5px $half-default-size;
        border-radius: 3px;
        border: 1px solid $blue;
        background-color: $blue;
        transition: .2s ease-out;
        cursor: pointer;

        a:not(.md-button) {
          color: $pure-white;
          font-weight: bold;

          &:hover {
            color: $pure-white;
          }
        }

        &:hover {
          background-color: $pure-white;

          a:not(.md-button) {
            color: $blue;
          }
        }
      }

      .active {
        background-color: $pure-white;

        a:not(.md-button) {
          color: $blue;
        }
      }
    }

    &__modal {
      .md-divider {
        margin: $default-size 0;
      }
    }

    &__functions {
      float: right;
    }

    &__dialog-message {
      margin: 0 $default-size;
    }

    &__edit-order {
      overflow-y: auto;

      .md-tabs-navigation {
        padding: 0;
      }

      &-content {
        padding: $default-size 0;
      }

      p {
        font-weight: bold;
      }

      .md-field {
        display: inline-flex;
        width: auto;
        margin: 0;
        padding: 0;
        min-height: 16px;
        text-align: left;
      }

      &-address {
        display: inline-block;
        width: calc(50% - 2px);
        padding: 0 10px 0 0;
        margin-top: $default-size;
        box-sizing: border-box;

        .md-field {
          width: 100%;
        }
      }
    }

    &__status {
      font-weight: bold;
      text-transform: uppercase;
    }

    &__financial-status {
      padding: 5px $half-default-size;
      background-color: $material-red;
      color: $pure-white;
      font-weight: bold;
      text-transform: uppercase;

      &--green {
        background-color: $green;
      }
      &--orange {
        background-color: $orange;
      }
    }

    &__product {
      border-bottom: 1px solid $blue;
      padding-bottom: 5px;
      margin-bottom: 5px;
      text-transform: capitalize;

      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .md-table {
      display: block;
      overflow: visible;
      margin-bottom: 2px;

      @media (max-width: $tablet) {
        margin: 0 !important;
      }

      table, &-content {
        overflow: visible;
      }

      .md-speed-dial {
        .md-fab {
          background-color: transparent !important;
          box-shadow: none;

          .md-icon {
            color: $pure_black !important;
          }
        }
      }
    }

    .md-speed-dial {
      position: relative;

      .md-fab {
        width: $double-default-size;
        height: $double-default-size;
      }

      &-content {
        position: absolute;
        top: $double-default-size;
        left: 0;
      }
    }

    .md-table-alternate-header {
      position: sticky;
      z-index: 10;
    }
  }
</style>
