<template>
  <section class="finance">
    <save_button
      :show="show_save_button && permissions.finance.module"
      :section="$translate('side_menu.finance')"
      @save="save_changed_configuration"
      @cancel="fetch_financials"
    />

    <div v-if="!$route.params.order_id && finance_type !== analytics && finance_type !== orders" class="finance__type-chooser">
      <md-button class="md-icon-button refresh-admin-data" @click="fetch_financials">
        <md-icon>cached</md-icon>
        <md-tooltip md-direction="top">{{ $translate("refresh") }}</md-tooltip>
      </md-button>
    </div>

    <orders_comp
      v-if="(finance_type === orders || $route.params.order_id) && permissions.finance.sections.orders.module"
      :can_user_manage_financials="permissions.finance.sections.orders.module"
      :data_source="data_source"
      ref="order_comp"
    />
    <discounts_comp
      v-else-if="finance_type === discounts && permissions.finance.sections.discounts.module"
      :can_user_manage_financials="permissions.finance.sections.discounts.module"
      :data_source="data_source"
      @fetch_data="fetch_financials"
    />
    <analytics_comp
      v-else-if="finance_type === analytics && permissions.finance.sections.analytics.module && total_orders"
      @fetch_data="fetch_financials"
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import {
  orders, discounts, FINANCE_STORE, ADMIN_STORE, USER_STORE, shopify, imported, PRODUCTS_STORE, CONFIGURATION_STORE,
  analytics
} from "../../constants/others_constants"
import save_button from "../utils/save_button"
import orders_comp from "../utils/finance/orders"
import discounts_comp from "../utils/finance/discounts"
import analytics_comp from "../utils/finance/finance_analytics"
import { FINANCE_DATA_UPDATED, FETCH_SHOPIFY_FINANCIALS, UPDATE_LOADER, FETCH_IMPORTED_FINANCIALS, FETCH_SHOPIFY_DATA } from "../../stores/Admin/constants"
import { GET_ORDERS, SAVE_FINANCE } from "../../stores/Admin/finance/constants"
import shopify_icon from "../../../Shared/components/icon_components/shopify-icon"
import ulsemo_icon from "../../../Shared/components/icon_components/ulsemo-icon"
import { FETCH_IMPORTED_PRODUCTS } from "../../stores/Admin/products/constants"

export default {
  components: {
    orders_comp,
    save_button,
    shopify_icon,
    ulsemo_icon,
    discounts_comp,
    analytics_comp
  },
  data() {
    return {
      data_source: imported,
      orders,
      discounts,
      imported,
      shopify,
      analytics
    }
  },
  computed: {
    ...mapState(ADMIN_STORE, [
      "user_edited_finance_data",
      "error_list"
    ]),
    ...mapState(USER_STORE, ["permissions"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_collections",
      "shopify_products",
    ]),
    ...mapState(FINANCE_STORE, {
      total_orders: "orders"
    }),
    ...mapState(CONFIGURATION_STORE, ["shopify_config"]),
    show_save_button() {
      return this.user_edited_finance_data && !this.error_list.length
    },
    finance_type() {
      return this.$route.params.finance_type
    }
  },
  mounted() {
    if (!this.permissions.finance.module) this.$router.push({ name: "Admin"})
    if (this.shopify_config && this.shopify_config.should_use_shopify_data) this.data_source = shopify
    if (this.finance_type !== orders && !this.total_orders) this.fetch_financials() // Orders load their own data
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      finance_data_update: FINANCE_DATA_UPDATED,
      update_loader: UPDATE_LOADER
    }),
    ...mapActions(PRODUCTS_STORE, {
      fetch_imported_data: FETCH_IMPORTED_PRODUCTS
    }),
    ...mapActions(FINANCE_STORE, {
      save_finance: SAVE_FINANCE,
      fetch_orders: GET_ORDERS
    }),
    ...mapActions(ADMIN_STORE, {
      fetch_shopify_financials: FETCH_SHOPIFY_FINANCIALS,
      fetch_imported_financials: FETCH_IMPORTED_FINANCIALS,
      fetch_shopify_data: FETCH_SHOPIFY_DATA,
    }),
    async save_changed_configuration() {
      this.save_finance()
    },
    async fetch_products() {
      if (!this[`${this.data_source}_products`]) {
        this.update_loader()

        await this[`fetch_${this.data_source}_data`]()

        this.update_loader(false)
      }
    },
    async fetch_financials() {
      this.update_loader()

      await Promise.all([
        // If on orders component, use its Fetch_orders function with presets like search, page size, etc
        this.fetch_orders(),
        this[`fetch_${this.data_source}_financials`]()
      ])

      this.finance_data_update(false)
      this.update_loader(false)
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .finance {
    &__type {
      &-chooser {
        margin-bottom: $half-default-size !important;

        svg {
          display: inline-block !important;
          width: 30px !important;
          height: 30px !important;
          vertical-align: middle !important;
        }
      }

      &-icon {
        display: inline-block !important;
        width: 45px !important;
        height: 45px !important;
        vertical-align: middle !important;
        border: 1px solid $pure_black !important;

        .md-icon {
          font-size: 30px !important;
        }

        .md-button-content {
          line-height: 19px !important;
        }

        &--selected {
          border-color: $green !important;
        }
      }
    }
  }
</style>
