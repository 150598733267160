<template functional>
  <svg class="refund-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    fill="#000"
    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <g>
      <g>
        <path d="M501.344,245.336c-5.906,0-10.688,4.773-10.688,10.664c0,31.688-6.188,62.422-18.422,91.344
          c-11.828,27.938-28.734,53.031-50.297,74.594s-46.656,38.469-74.594,50.297c-28.922,12.234-59.656,18.422-91.344,18.422
          s-62.422-6.188-91.336-18.422c-27.938-11.828-53.039-28.734-74.602-50.297c-21.555-21.562-38.477-46.656-50.297-74.594
          c-12.227-28.922-18.43-59.656-18.43-91.344s6.203-62.422,18.43-91.336c11.82-27.945,28.742-53.039,50.297-74.602
          c21.562-21.555,46.664-38.477,74.602-50.297c28.914-12.234,59.648-18.43,91.336-18.43s62.422,6.195,91.344,18.43
          c25.844,10.938,49.281,26.258,69.719,45.57h-39.156c-5.875,0-10.656,4.773-10.656,10.664s4.781,10.664,10.656,10.664h64
          c5.906,0,10.688-4.773,10.688-10.664V32c0-5.891-4.781-10.664-10.688-10.664c-5.875,0-10.656,4.773-10.656,10.664v37.398
          C385.438,26.359,323.812,0,256,0C114.617,0,0,114.617,0,256c0,141.375,114.617,256,256,256c141.375,0,256-114.625,256-256
          C512,250.109,507.219,245.336,501.344,245.336z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M106.664,149.336v85.328V320h298.68v-85.336v-85.328H106.664z M384,234.664v63.992H128v-63.992v-64h256V234.664z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M255.812,180.664c-29.461,0-53.336,23.883-53.336,53.336s23.876,53.344,53.336,53.344
          c29.453,0,53.328-23.891,53.328-53.344S285.265,180.664,255.812,180.664z M255.812,266c-17.648,0-32-14.359-32-32
          c0-17.648,14.352-32,32-32c17.641,0,32,14.352,32,32C287.812,251.641,273.453,266,255.812,266z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M394.656,330.656h-277.32c-5.891,0-10.672,4.781-10.672,10.688c0,5.875,4.781,10.656,10.672,10.656h277.32
          c5.906,0,10.688-4.781,10.688-10.656C405.344,335.438,400.562,330.656,394.656,330.656z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M394.656,362.656h-277.32c-5.891,0-10.672,4.781-10.672,10.688c0,5.875,4.781,10.656,10.672,10.656h277.32
          c5.906,0,10.688-4.781,10.688-10.656C405.344,367.438,400.562,362.656,394.656,362.656z"/>
      </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">
  .refund-icon {
    width: 100%;
    height: 100%;
    margin-top: 3px;
  }
</style>
