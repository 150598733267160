import validation_imports from "./validation_imports"
import transform_error_list from "../transform_errors_list"
import { MISSING_NAME, DEFAULT_ERROR } from "../../constants/validation/error_types"
import { ADMIN, shopify } from "../../constants/others_constants"

export default {
  ...validation_imports(ADMIN),
  async validate() {

    // Clear error list and reset validator
    this.clear_errors("discounts")

    // Defining validator without reactivity
    this.validator = true

    if (!this.discount_code) this.transforming_errors(
      await MISSING_NAME(this.$translate("discounts.discount_code_error_name"), "discounts", " ")
    )

    if (this.applies_to !== this.applicable_values.all && !this.selected_entity_ids.length) this.transforming_errors(
      DEFAULT_ERROR("discounts", this.$translate("errors.finance.discount_entities_not_selected"))
    )

    if (this.use_limit && !this.limit) this.transforming_errors(
      DEFAULT_ERROR("discounts", this.$translate("errors.finance.missing_limit_value"))
    )

    if (
      this.data_source === shopify &&
      this.requirement !== this.requirement_values.none &&
      !this.requirements_value
    ) this.transforming_errors(
      DEFAULT_ERROR("discounts", this.$translate("errors.finance.missing_requirements_value"))
    )

    if (
      this.data_source !== shopify &&
      this.requirement === this.requirement_values.minimum_amount &&
      !this.available_languages.every(lang =>
        this.requirement_values_for_translations[lang] ||
        this.requirement_values_for_translations[lang] === 0
      )
    ) this.transforming_errors(DEFAULT_ERROR("discounts", this.$translate("errors.finance.missing_translation_requirement_value")))

    if (this.data_source === shopify && !this.value) this.transforming_errors(
      DEFAULT_ERROR("discounts", this.$translate("errors.finance.missing_value"))
    )

    if (
      this.data_source !== shopify &&
      this.use_fixed_amount &&
      !this.available_languages.every(lang => this.values_for_translations[lang])
    ) this.transforming_errors(DEFAULT_ERROR("discounts", this.$translate("errors.finance.missing_translation_value")))

    if (this.use_ends_at && !this.ends_at) this.transforming_errors(
      DEFAULT_ERROR("discounts", this.$translate("errors.finance.missing_end_date"))
    )

    if (this.$moment(this.start_date).isAfter(this.ends_at)) this.transforming_errors(
      DEFAULT_ERROR("discounts", this.$translate("errors.finance.missing_end_date_before_start"))
    )
  
    return this.validator
  },
  transforming_errors(error, remove) {
    this.update_errors(transform_error_list(this.get_error_list(), error, remove, "menu-content"))
    this.validator = false
  }
}
