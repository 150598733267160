<template>
  <div class="currency_selector"  :class="{ 'currency_selector--closed': !selecting_currency }" v-if="selected_currency">
    <div class="currency_selector__selected" @click="selecting_currency = !selecting_currency">
      {{ $translate("select_currency") }}: <b>{{ selected_currency }} <arrow /></b>
    </div>
    <transition name="show-currencies">
      <div v-if="selecting_currency" class="currency_selector__options">
        <div
          v-for="(currency, index) in currencies"
          :key="`currency_selector-${index}`"
          @click="select_currency(currency)"
        >
          {{ currency }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import arrow from "../../../Shared/components/icon_components/arrow"

export default {
  props: {
    selected_currency: {
      type: String,
      required: false,
      default: ""
    },
    available_currencies: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  components: {
    arrow
  },
  data() {
    return {
      selecting_currency: false,
    }
  },
  computed: {
    currencies() {
      return this.available_currencies
    }
  },
  methods: {
    select_currency(currency) {
      this.selecting_currency = false
      this.$emit("currency_changed", currency)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .currency_selector {
    position: relative;
    cursor: pointer;

    svg {
      width: 15px;
      height: $half-default-size;
      transform: rotate(-90deg);
      transition: transform .2s;
    }

    &--closed {
      svg {
        transform: rotate(90deg);
      }
    }

    &__selected {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      align-items: center;

      b {
        margin-left: $half-default-size;
        padding: 5px $half-default-size;
        border: 1px solid $material-grey;
        border-radius: $border-radius;
      }
    }

    &__options {
      position: absolute;
      top: 100%;
      right: 0;
      padding: $half-default-size $default-size;
      z-index: 2;
      box-shadow: 0 8px 20px -6px $shadow-color;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      background-color: $pure-white;

      @media (max-width: $tablet--small) {
        right: -#{$half-default-size};
      }
    }

    .show-currencies-enter-active, .show-currencies-leave-active {
      transition: .5s;
    }
    .show-currencies-enter, .show-currencies-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
</style>
