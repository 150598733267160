<template>
  <div class="payment-util">
    <transition-group name="slide-down">
      <div v-show="!local_loader" key="stripe-element">
        <div class="md-subheading"><b>{{ $translate("total") }}: {{ calculated_amounts.total }}</b></div>
        <div>{{ $translate("handling_fees") }}: {{ calculated_amounts.fees }}</div>
        <br/>
        <stripe-elements
          ref="elementsRef"
          :pk="stripe_public_key"
          :amount="amount"
          locale="en"
          @token="auth_call"
        />
        <md-button class="md-success" @click="submit_payment">{{ $translate("pay") }}</md-button>
      </div>
      <Loader
        v-show="local_loader"
        key="loader-element"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import { StripeElements } from "vue-stripe-checkout";
import Loader from "@/applications/Shared/components/Loader/index.vue"
import stripe_config from "../../constants/stripe_config"
import { ADMIN, severities } from "../../constants/others_constants"
import { ADD_GLOBAL_ERROR } from "../../stores/Admin/constants"
import { create_project_payment } from "../../constants/endpoints/firebase"
import { calculate_admin_payment, handle_admin_payment } from "../../constants/endpoints/other";

export default {
  components: {
    Loader,
    StripeElements
  },
  props: {
    payment_type: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    payment_info: {
      type: String,
      required: false,
      default: ""
    },
    success_message: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      local_loader: false,
      calculated_amounts: {},
      stripe_config
    }
  },
  computed: {
    stripe_public_key() {
      return stripe_config[process.env.NODE_ENV].public_key
    }
  },
  watch: {
    amount() { this.calculate_final_price() }
  },
  mounted() {
    this.calculate_final_price()
  },
  methods: {
    ...mapMutations(ADMIN, {
      add_global_error: ADD_GLOBAL_ERROR,
    }),
    submit_payment() {
      this.$refs.elementsRef.submit();
    },
    async calculate_final_price() {
      this.local_loader = true
      const { data } = await calculate_admin_payment({ amount: this.amount })

      this.calculated_amounts = data
      this.local_loader = false
    },
    async auth_call(token, actions) {
      this.local_loader = true
      try {
        const charge = await handle_admin_payment({ source: token.id, amount: this.amount, payment_type: this.payment_type, other_info: this.payment_info })
        await create_project_payment({ payment_data: charge, type: this.payment_type })

        this.add_global_error({
          message: this.success_message || this.$translate("default_payment_success_message"),
          severity: severities.SUCCESS
        })
        this.$emit("payment_completed")
      } catch ({ message }) {
        this.add_global_error({ message })
      }
      this.local_loader = false
    },
  }
}
</script>

<style lang="scss">
  .payment-util {
    position: relative;

    .spinner {
      width: 100% !important;

      &-inner {
        margin: auto;
      }
    }
  }

  .slide-down-enter-active, .slide-down-leave-active {
    transition: .2s;
  }
  .slide-down-enter, .slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    min-height: 40px;
    opacity: 0;
    transform: translateY(-20px);
  }
</style>