<template>
  <section class="admin-discounts scroll-content">
    <h2 class="md-display-1">{{ $translate("discounts.title") }}:</h2>

    <section_description_preview>
      <div class="md-subheading" v-html="$translate('discounts.message')" />
    </section_description_preview>

    <md-table v-if="selected_price_rule_id === null && show_discounts" md-card class="admin-table">
      <md-table-row>
        <md-button
          data-cy="add-discount-code"
          class="md-icon-button md-raised md-primary"
          @click="() => { selected_price_rule_id = undefined; selected_discount_id = undefined }"
        >
          <md-icon>add</md-icon>
          <md-tooltip md-direction="top">
            {{ $translate("add") }} {{ $translate("discounts.discount_code") }}
          </md-tooltip>
        </md-button>
        <md-table-head>{{ $translate("title_translation") }}</md-table-head>
        <md-table-head>{{ $translate("discounts.activity_state") }}</md-table-head>
        <md-table-head>{{ $translate("discounts.number_of_uses") }}</md-table-head>
        <md-table-head>{{ $translate("discounts.active_from") }}</md-table-head>
        <md-table-head>{{ $translate("actions") }}</md-table-head>
      </md-table-row>

      <md-table-row
        v-for="({ code, created_at, price_rule_id, id, usage_count }) in discounts"
        :key="id"
      >
        <md-table-cell />
        <md-table-cell data-cy="code">{{ code }}</md-table-cell>
        <md-table-cell data-cy="state">{{ is_discount_active(price_rule_id, usage_count) }}</md-table-cell>
        <md-table-cell data-cy="usage-count">{{ usage_count || 0 }}x</md-table-cell>
        <md-table-cell data-cy="active-from">{{ $moment(created_at).format(date_time_format) }}</md-table-cell>
        <md-table-cell>
          <div class="admin-discounts__actions">
            <md-button
              data-cy="edit-code"
              class="md-icon-button md-raised md-primary"
              @click="() => { selected_price_rule_id = price_rule_id; selected_discount_id = id }"
            >
              <md-icon>edit</md-icon>
              <md-tooltip md-direction="top">
                {{ $translate("edit") }}
              </md-tooltip>
            </md-button>
            <md-button
              data-cy="delete-code"
              class="md-icon-button md-raised md-accent"
              @click="delete_discount(price_rule_id, id)"
            >
              <md-icon>delete</md-icon>
              <md-tooltip md-direction="top">
                {{ $translate("delete") }}
              </md-tooltip>
            </md-button>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div v-else class="admin-discounts__detail">
      <md-button
        data-cy="back-to-discounts"
        class="md-raised md-accent"
        @click="clear_selected"
      >
        {{ $translate("back") }}
      </md-button>

      <div class="md-elevation-3 admin-discounts__detail-info">
        <h3 class="md-title">{{ $translate("discounts.code_title") }}:</h3>
        <p class="md-body-1">{{ $translate("discounts.code_message") }}</p>
        <md-field>
          <md-input data-cy="discount-code" v-model="discount_code" :placeholder="$translate('discounts.codes.code_placeholder')"/>
          <span class="md-helper-text">{{ $translate("discounts.codes.code_input_message") }}</span>
        </md-field>
      </div>

      <div class="md-elevation-3 admin-discounts__detail-info">
        <p class="md-title">{{ $translate("discounts.codes.types.title") }}:</p>
        <md-radio data-cy="fixed-amount" v-model="use_fixed_amount" :value="true">{{ $translate("discounts.codes.types.fixed") }}</md-radio>
        <md-radio data-cy="percentage" v-model="use_fixed_amount" :value="false">{{ $translate("discounts.codes.types.percentage") }}</md-radio>
      </div>

      <div class="md-elevation-3 admin-discounts__detail-info">
        <p class="md-title">{{ $translate("discounts.codes.value.title") }}:</p>
        <currency_selector
          v-if="data_source !== shopify && use_fixed_amount"
          :selected_currency="selected_currency"
          :available_currencies="available_currencies"
          @currency_changed="currency => selected_currency = currency"
        />
        <md-field>
          <label>{{ $translate("discounts.codes.value.discount_value") }}</label>
          <md-input data-cy="discount-value" v-model="discount_value" type="number"/>
          <span class="md-suffix">{{ use_fixed_amount ? get_correct_currency_sign : "%" }}</span>
        </md-field>

        <p class="md-body-1">{{ $translate("discounts.codes.value.applies_to") }}:</p>
        <md-radio data-cy="all-products" v-model="applies_to" :value="applicable_values.all">{{ $translate("discounts.codes.value.all") }}</md-radio>
        <md-radio data-cy="specific-products" v-model="applies_to" :value="applicable_values.products">{{ $translate("discounts.codes.value.specific_product") }}</md-radio>
        <md-radio data-cy="specific-collections" v-model="applies_to" :value="applicable_values.collections">{{ $translate("discounts.codes.value.specific_collection") }}</md-radio>
        <md-button data-cy="choose-products-collections" v-if="applies_to !== applicable_values.all" class="md-raised" @click="open_selection_modal = !open_selection_modal">
          {{ $translate("discounts.selection_title") }}
        </md-button>

        <div
          v-for="entity in get_correct_entities"
          :key="entity.id"
          class="admin-discounts__entity"
        >
          <img :src="entity.images ? (entity.images[0].thumb_src || entity.images[0].src) : '/static/backgrounds/default-img.jpg'">
          <span>{{ get_correct_translation_value(entity, "title", [user_info.language_code]) }}</span>
          <md-button
            class="md-fab md-mini md-raised md-accent"
            @click="selected_entity_ids = selected_entity_ids.filter(entity_id => entity_id !== entity.id)"
          >
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="top">{{ $translate("delete") }}</md-tooltip>
          </md-button>
        </div>
      </div>

      <div class="md-elevation-3 admin-discounts__detail-info">
        <p class="md-title">{{ $translate("discounts.codes.requirements.title") }}:</p>
        <md-radio data-cy="no-min-requirements" v-model="requirement" :value="requirement_values.none">{{ $translate("discounts.codes.requirements.none") }}</md-radio>
        <md-radio data-cy="minumum-purchase-amount" v-model="requirement" :value="requirement_values.minimum_amount">{{ $translate("discounts.codes.requirements.minimum_amount") }}</md-radio>
        <md-radio data-cy="minimum-quantity-of-items" v-model="requirement" :value="requirement_values.minimum_quantity">{{ $translate("discounts.codes.requirements.minimum_items") }}</md-radio>
        <div v-if="data_source !== shopify && requirement !== requirement_values.none">
          <currency_selector 
            :selected_currency="selected_currency"
            :available_currencies="available_currencies"
            @currency_changed="currency => selected_currency = currency"
          />
        </div>
        <md-field v-if="requirement !== requirement_values.none">
          <md-input data-cy="minimum-requirement" v-model="requirement_value" type="number"/>
          <span class="md-suffix">{{ requirement === requirement_values.minimum_amount ? get_correct_currency_sign : "" }}</span>
        </md-field>
      </div>

      <div class="md-elevation-3 admin-discounts__detail-info">
        <p class="md-title">{{ $translate("discounts.codes.limits.title") }}:</p>
        <md-checkbox data-cy="limit-discount-usage" v-model="use_limit" :value="true">{{ $translate("discounts.codes.limits.total_usage") }}</md-checkbox>
        <md-field v-if="use_limit">
          <md-input data-cy="usage-times" v-model="limit" type="number"/>
        </md-field>
      </div>

      <div class="md-elevation-3 admin-discounts__detail-info">
        <p class="md-title">{{ $translate("discounts.codes.dates.title") }}:</p>
        <p class="md-body-1">{{ $translate("discounts.codes.dates.start") }}:</p>
        <datetime v-model="start_date" type="datetime"/>
        <md-checkbox v-model="use_ends_at" :value="true">{{ $translate("discounts.codes.dates.end") }}</md-checkbox>
        <div v-if="use_ends_at">
          <p class="md-body-1">{{ $translate("discounts.codes.dates.end") }}:</p>
          <datetime v-model="ends_at" type="datetime"/>
        </div>
      </div>

      <md-button
        data-cy="save-discount"
        class="md-raised md-primary"
        @click="save_discount"
      >
        {{ $translate("save") }}
      </md-button>

      <md-button
        v-if="selected_price_rule_id"
        class="md-raised md-accent"
        @click="delete_discount(selected_price_rule_id, selected_discount_id)"
      >
        {{ $translate("delete") }}
      </md-button>
    </div>

    <entity_selection_modal
      :modal_title="$translate('discounts.selection_title')"
      :open_modal="open_selection_modal"
      :enable_product_selection="applies_to === applicable_values.products"
      :enable_collection_selection="applies_to === applicable_values.collections"
      @entity_selected="select_entity"
      @toggle_modal="open_selection_modal = false"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { Datetime } from "vue-datetime"
import { CONFIGURATION_STORE, FINANCE_STORE, PRODUCTS_STORE, shopify, USER_STORE, ADMIN } from "../../../constants/others_constants"
import entity_selection_modal from "../entity_selection_modal"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import { DELETE_DISCOUNT_CODE, FETCH_AND_ASSING_MOMENT_LIBRARY, UPDATE_DISCOUNT_CODES } from "../../../stores/Admin/constants"
import price_rule_object from "../../../constants/empty_objects/price_rule_object"
import discount_code_object from "../../../constants/empty_objects/discount_code_object"
import { currency_signs, date_time_format } from "../../../../Shared/constants/other"
import { UPDATE_DISCOUNT_CODE, UPDATE_PRICE_RULE } from "../../../stores/Admin/finance/constants"
import validate from "../../../methods/validations/discounts"
import currency_selector from "../../../../Shared/components/utils/currency_selector"
import section_description_preview from "../section_description_preview"

const default_values = {
  use_fixed_amount: true,
  use_limit: false,
  use_ends_at: false,
  discount_code: "",
  applies_to: "all",
  value: "",
  values_for_translations: {},
  requirement: "none",
  requirements_value: 0,
  requirement_values_for_translations: {},
  selected_discount_id: null,
  selected_price_rule_id: null,
  limit: null,
  start_date: null,
  ends_at: null,
  selected_entity_ids: [],
}

export default {
  components: {
    datetime: Datetime,
    entity_selection_modal,
    currency_selector,
    section_description_preview
  },
  props: {
    data_source: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show_discounts: false,
      open_selection_modal: false,
      selected_currency: null,
      applicable_values: {
        all: "all",
        collections: "collections",
        products: "products"
      },
      requirement_values: {
        none: "none",
        minimum_amount: "minimum_amount",
        minimum_quantity: "minimum_quantity"
      },
      date_time_format,
      shopify,
      currency_signs,
      ...default_values
    }
  },
  computed: {
    ...mapState(FINANCE_STORE, [
      "imported_discount_codes", "shopify_discount_codes",
      "imported_price_rules", "shopify_price_rules"
    ]),
    ...mapState(USER_STORE, ["permissions", "user_info"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_collections",
      "shopify_products"
    ]),
    ...mapState(CONFIGURATION_STORE, ["translations"]),
    ...mapState(ADMIN, ["$moment"]),
    get_correct_currency_sign() {
      return currency_signs[this.selected_currency]
    },
    discounts() {
      return Object.values((this.data_source === shopify ? this.shopify_discount_codes : this.imported_discount_codes) || {})
    },
    price_rules() {
      return Object.values((this.data_source === shopify ? this.shopify_price_rules : this.imported_price_rules) || {})
    },
    discount_value: {
      get() {
        return (this.data_source === shopify || !this.use_fixed_amount) ?
          this.value :
          this.values_for_translations[
            this.available_languages[this.available_currencies.findIndex(cur => cur === this.selected_currency)]
          ] || 0
      },
      set(value) {
        if (this.data_source === shopify || !this.use_fixed_amount) this.value = value
        else this.values_for_translations[
          this.available_languages[this.available_currencies.findIndex(cur => cur === this.selected_currency)]
        ] = value
      }
    },
    requirement_value: {
      get() {
        return (this.data_source === shopify || this.requirement === this.requirement_values.minimum_quantity) ?
          this.requirements_value :
          this.requirement_values_for_translations[this.available_languages[
            this.available_currencies.findIndex(cur => cur === this.selected_currency)
          ]] || ""
      },
      set(value) {
        if (this.data_source === shopify || this.requirement === this.requirement_values.minimum_quantity) this.requirements_value = value
        else this.requirement_values_for_translations[
          this.available_languages[this.available_currencies.findIndex(cur => cur === this.selected_currency)]
        ] = value
      }
    },
    get_correct_price_rule() {
      return this.get_price_rule(this.selected_price_rule_id)
    },
    available_languages() {
      return Object.keys(this.translations)
    },
    available_currencies() {
      return Array.from(new Set(Object.values(this.translations).map(({ currency_code }) => currency_code)))
    },
    collections() {
      return [
        ...(this.imported_collections || []),
        ...(this.shopify_collections || []),
      ]
    },
    products() {
      return [
        ...(this.imported_products || []),
        ...(this.shopify_products || []),
      ]
    },
    get_correct_entities() {
      return (this.applies_to === this.applicable_values.products ? this.products : this.collections)
        .filter(({ id }) => this.selected_entity_ids.includes(id))
    }
  },
  watch: {
    applies_to() {
      this.selected_entity_ids = []
    },
    selected_price_rule_id(val) {
      if (val && this.get_correct_price_rule) {
        this.use_fixed_amount = this.get_correct_price_rule.value_type === "fixed_amount"
        this.use_limit = !!this.get_correct_price_rule.usage_limit
        this.use_ends_at = !!this.get_correct_price_rule.ends_at
        this.discount_code = this.get_correct_price_rule.title
        this.value = get_correct_translation_value(this.get_correct_price_rule, "value", [this.available_languages], false) * -1
        this.requirements_value = (
          this.get_correct_price_rule.prerequisite_quantity_range ||
          this.get_correct_price_rule.prerequisite_subtotal_range  ||
          {}
        ).greater_than_or_equal_to

        this.values_for_translations = this.get_correct_price_rule.translations ?
          Object
            .entries(this.get_correct_price_rule.translations)
            .reduce((tot, [lang, { value }]) => ({ ...tot, [lang]: (value || 0) * -1 }), {}) :
          {}
        this.requirement_values_for_translations = this.get_correct_price_rule.translations ?
          Object
            .entries(this.get_correct_price_rule.translations)
            .reduce((tot, [lang, { requirements_value }]) => ({ ...tot, [lang]: requirements_value || 0 }), {}) :
          {}
        this.limit = this.get_correct_price_rule.usage_limit
        this.start_date = this.get_correct_price_rule.starts_at
        this.ends_at = this.get_correct_price_rule.ends_at || null

        if (
          this.get_correct_price_rule.entitled_collection_ids &&
          this.get_correct_price_rule.entitled_collection_ids.length
        ) this.applies_to = this.applicable_values.collections
        else if (
          this.get_correct_price_rule.entitled_product_ids &&
          this.get_correct_price_rule.entitled_product_ids.length
        ) this.applies_to = this.applicable_values.products
        else this.applies_to = this.applicable_values.all

        if (this.get_correct_price_rule.prerequisite_quantity_range) this.requirement = this.requirement_values.minimum_quantity
        else if (this.get_correct_price_rule.prerequisite_subtotal_range) this.requirement = this.requirement_values.minimum_amount
        else this.requirement = this.requirement_values.none

        this.$nextTick(() => {
          if (
            this.get_correct_price_rule.entitled_collection_ids &&
            this.get_correct_price_rule.entitled_collection_ids.length
          ) this.selected_entity_ids = this.get_correct_price_rule.entitled_collection_ids
          else if (
            this.get_correct_price_rule.entitled_product_ids &&
            this.get_correct_price_rule.entitled_product_ids.length
          ) this.selected_entity_ids = this.get_correct_price_rule.entitled_product_ids
          else this.selected_entity_ids = []
        })
      } else this.start_date = this.$moment().format()
    },
    data_source() {
      this.$emit("fetch_data")
    }
  },
  async mounted() {
    this.$emit("fetch_data")
    await this.fetch_and_assing_moment()

    this.start_date = this.$moment().format()
    if (this.data_source !== shopify) this.selected_currency = this.available_currencies[0]
    else this.selected_currency = "USD"
    this.show_discounts = true
  },
  methods: {
    ...mapMutations(FINANCE_STORE, {
      update_discount_code: UPDATE_DISCOUNT_CODE,
      update_price_rule: UPDATE_PRICE_RULE
    }),
    ...mapActions(FINANCE_STORE, {
      update_discount_codes: UPDATE_DISCOUNT_CODES,
      delete_discount_code: DELETE_DISCOUNT_CODE
    }),
    ...mapActions(ADMIN, {
      fetch_and_assing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY
    }),
    ...validate,
    get_correct_translation_value,
    is_discount_active(price_rule_id, usage_count) {
      const price_rule = this.get_price_rule(price_rule_id)
      const current_date = this.$moment().format()
      const has_time_expired = price_rule.ends_at && this.$moment(current_date).isSameOrAfter(price_rule.ends_at)

      return (price_rule.usage_limit === usage_count || has_time_expired) ? this.$translate('expired') : this.$translate('active')
    },
    get_price_rule(price_rule_id) {
      return this.price_rules.find(({ id }) => price_rule_id === id)
    },
    select_entity(collection_or_product_id) {
      this.selected_entity_ids = Array.from(new Set([
        ...this.selected_entity_ids,
        this.applies_to === this.applicable_values.products ? collection_or_product_id : collection_or_product_id.id
      ]))
    },
    async save_discount() {
      if (!this.validate()) return

      const price_rule = price_rule_object({
        title: this.discount_code,
        entitled_collection_ids: this.applies_to === this.applicable_values.collections ? this.selected_entity_ids : [],
        entitled_product_ids: this.applies_to === this.applicable_values.products ? this.selected_entity_ids : [],
        value: String(Number(this.value) * -1), // Shopify requires the value to be negative
        starts_at: this.start_date,
        created_at: this.data_source === shopify ? null : this.$moment().format(),
        ends_at: this.ends_at,
        is_percentage: !this.use_fixed_amount,
        usage_limit: this.limit,
        id: this.selected_price_rule_id,
        ...(
          this.requirement === this.requirement_values.minimum_quantity ? {
            prerequisite_quantity_range: { greater_than_or_equal_to: this.requirements_value }
          } : 
            this.requirement === this.requirement_values.minimum_amount ? {
              prerequisite_subtotal_range: { greater_than_or_equal_to: this.requirements_value }
            } : {}
        ),
        ...(
          this.data_source !== shopify && (this.requirement_values.minimum_amount || this.use_fixed_amount) && {
            translations: this.available_languages.reduce((tot, lang) => ({
              ...tot,
              [lang]: {
                value: this.use_fixed_amount ? String(Number(this.values_for_translations[lang]) * -1) : undefined,
                requirements_value: this.requirement_values.minimum_amount ? Number(this.requirement_values_for_translations[lang]) : undefined
              }
            }), {})
          }
        )
      })
      const discount_code = discount_code_object({
        code: this.discount_code,
        price_rule_id: price_rule.id,
        id: this.selected_discount_id,
        created_at: this.$moment().format(),
        usage_count: (this.selected_discount_id || this.data_source === shopify) ? null : 0
      })

      await this.update_discount_codes([this.selected_price_rule_id, discount_code, price_rule, this.data_source])
      this.$emit("fetch_data")

      this.selected_price_rule_id = price_rule.id
      this.selected_discount_id = discount_code.id
    },
    delete_discount(price_rule_id, discount_id) {
      this.delete_discount_code([price_rule_id, discount_id, this.data_source])
      this.clear_selected()
    },
    clear_selected() {
      Object.entries(default_values).forEach(([key, value]) => this[key] = value)
      this.clear_errors("discounts")
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;
  @use "../../../../../../node_modules/vue-datetime/dist/vue-datetime.css" as *;

  .admin-discounts {
    &__entity {
      display: grid;
      grid-template-columns: 50px calc(100% - 100px) 50px;
      max-width: 300px;
      margin-bottom: $half-default-size;
      align-items: center;

      span:nth-child(2) {
        padding: 5px $half-default-size;
      }
      .md-button {
        margin: 0 !important;
      }
    }

    &__detail {
      .md-button {
        margin: $default-size $default-size $default-size 0 !important;
      }

      .md-content {
        padding: $half-default-size $default-size;
        border-radius: $border-radius;
        margin-bottom: $default-size;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
    }

    &__detail-info {
      padding: $half-default-size;
      margin-bottom: $default-size;
      border-radius: $border-radius;
    }

    .currency_selector {
      display: inline-block;

      &__selected {
        justify-content: flex-start;
        padding-top: $default-size;
      }

      &__options {
        z-index: 4;
      }
    }

    .md-tab {
      padding: $default-size 0;
    }

    .md-field {
      max-width: 500px;
    }

    .md-title {
      margin: 0;
    }

    .md-card {
      margin: 0 !important;
      overflow: auto !important;
    }

    .md-table-row {
      &:first-child {
        .md-button {
          margin: 9px $half-default-size 0 0 !important;
          float: right;
        }
      }
    }

    .vdatetime-input {
      padding: 5px 0;
      border-radius: $border-radius;
      text-align: center;
      border-color: $material-grey--darkest;
    }
  }
</style>
