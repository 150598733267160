<template>
  <section class="order-detail">
    <div v-if="final_order.id">
      <p class="md-title">
        {{ $translate("orders.order_detail.info_title") }}

        <md-speed-dial v-if="permissions.finance.sections.orders.module" md-event="click" md-direction="bottom">
          <md-speed-dial-target class="md-primary">
            <md-icon>more_horiz</md-icon>
          </md-speed-dial-target>

          <md-speed-dial-content>
            <md-button class="md-icon-button" @click="update_orders(order_action_types.close)">
              <md-icon>delete_forever</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.close_order") }}</md-tooltip>
            </md-button>

            <md-button class="md-icon-button" @click="update_orders(order_action_types.open)">
              <md-icon>restore_from_trash</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.reopen_order") }}</md-tooltip>
            </md-button>

            <md-button class="md-icon-button" @click="update_orders(order_action_types.cancel)">
              <md-icon>close</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.cancel_order") }}</md-tooltip>
            </md-button>

            <md-button class="md-icon-button" @click="update_orders(order_action_types.fulfill)">
              <md-icon>check_circle_outline</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.fulfill_order") }}</md-tooltip>
            </md-button>

            <md-button class="md-icon-button" @click="$emit('initialize_order_edit', [false, final_order])">
              <md-icon>create</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.edit_order") }}</md-tooltip>
            </md-button>

            <md-button v-if="!is_order_shopify" class="md-icon-button" @click="$emit('refund_selected_order', final_order)">
              <refund_icon/>
              <md-tooltip md-direction="top">{{ $translate("orders.refund_order") }}</md-tooltip>
            </md-button>

            <md-button v-if="!is_order_shopify" class="md-icon-button" @click="update_orders(order_financial_statuses.paid, false)">
              <md-icon>monetization_on</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.mark_as_paid") }}</md-tooltip>
            </md-button>

            <md-button v-if="is_order_shopify" class="md-icon-button" @click="$emit('show_orders_info_message', [true, final_order])">
              <md-icon>info</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.other_options_info") }}</md-tooltip>
            </md-button>

            <md-button v-if="!is_order_shopify" class="md-icon-button" @click="$emit('show_shipping_modal', [true, final_order])">
              <md-icon>request_quote</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.shipping_and_label") }}</md-tooltip>
            </md-button>

            <md-button
              v-if="!is_order_shopify && is_zasilkovna_order"
              class="md-icon-button"
              :disabled="order.fulfillment_status !== order_fulfillment_statuses.unshipped"
              @click="$emit('send_zasilkovna_package', final_order, is_order_shopify ? shopify : imported)"
            >
              <md-icon><img src="/static/icons/zasilkovna-logo-small.png" alt="zasilkovna"></md-icon>
              <md-tooltip md-direction="top">
                {{ $translate(`orders.${order.fulfillment_status === order_fulfillment_statuses.unshipped ? "create_zasilkovna_packet" : "zasilkovna_packet_created"}`) }}
              </md-tooltip>
            </md-button>
  
            <md-button v-if="!is_order_shopify && !is_zasilkovna_order" class="md-icon-button" @click="$emit('initialize_order_edit', [true, final_order])">
              <md-icon>local_shipping</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.update_tracking_id") }}</md-tooltip>
            </md-button>

            <md-button class="md-icon-button" @click="$emit('download_order', order_id)">
              <md-icon>file_download</md-icon>
              <md-tooltip md-direction="top">{{ $translate("orders.file_download") }}</md-tooltip>
            </md-button>
          </md-speed-dial-content>
        </md-speed-dial>
      </p>
      <div class="md-layout">
        <md-content class="order-detail__info md-layout-item md-elevation-3">
          <div><span>{{ $translate("orders.table.order_number") }}:</span> <span>{{ final_order.order_number }}</span></div>
          <div><span>{{ $translate("orders.table.created_at") }}:</span> <span>{{ get_formatted_date }}</span></div>
          <div v-if="final_order.paypal_data">
            <span>{{ $translate("orders.table.contact_email") }}:</span> <span>{{ final_order.paypal_data.payer.email_address }}</span>
          </div>
          <div
            class="orders__financial-status"
            :class="{
              'orders__financial-status--green': final_order.financial_status === 'paid',
              'orders__financial-status--orange': final_order.financial_status === 'pending'
            }"
          >
            <span>{{ $translate("orders.table.financial_status") }}:</span>
            <span class="order-detail--capitalize">{{ $translate(`orders.table.statuses.${final_order.financial_status}`) }}</span>
          </div>
          <div><span>{{ $translate("orders.table.status") }}:</span> <span class="order-detail--capitalize">
            {{ $translate(`orders.table.statuses.${
              final_order.closed_at ?
                order_statuses.closed : final_order.cancelled_at ?
                  order_statuses.cancelled : order_statuses.open
            }`) }}
          </span></div>
          <div><span>{{ $translate("orders.table.fulfillment_status") }}:</span> <span class="order-detail--capitalize">
            {{ $translate(`orders.table.statuses.${final_order.fulfillment_status}`) }}
          </span></div>
          <div><span>{{ $translate("orders.payment_method") }}:</span> <span class="order-detail--capitalize">
            {{ $translate(`orders.payment_methods.${final_order.payment_method || payment_methods.paypal}`) }}
          </span></div>
        </md-content>
        <md-content class="order-detail__info md-layout-item md-elevation-3">
          <div><span>{{ $translate("orders.table.total_line_items_price") }}:</span> <span>{{ currency }} {{ final_order.total_line_items_price }}</span></div>
          <div><span>{{ $translate("orders.table.total_product_discount") }}:</span> <span>{{ currency }} {{ product_discounts }}</span></div>
          <div v-if="final_order.total_tax"><span>{{ $translate("orders.table.total_tax") }}:</span> <span>{{ currency }} {{ Number(final_order.total_tax).toFixed(2) }}</span></div>
          <div><span>{{ $translate("orders.table.subtotal_price") }}:</span> <span>{{ currency }} {{ final_order.subtotal_price }} {{ $translate("orders.table.incl_tax") }}</span></div>
          <div><span>{{ $translate("orders.table.total_shipping_discounts") }}:</span> <span>{{ currency }} {{ total_shipping_discounts }}</span></div>
          <div><span>{{ $translate("orders.table.total_shipping_price") }}:</span> <span>{{ currency }} {{ total_shipping_price }}</span></div>
          <div v-if="final_order.total_discounts" class="md-alert"><span>{{ $translate("orders.table.total_discounts") }}:</span> <span>{{ currency }} -{{ final_order.total_discounts }}</span></div>
          <div v-if="final_order.cross_product_profit_share" class="md-alert"><span>{{ $translate("orders.table.cross_revenue_reduction") }}:</span> <span>{{ currency }} -{{ final_order.cross_product_profit_share }}</span></div>
          <div><span>{{ $translate("orders.table.total_price") }}:</span> <span><b>{{ currency }} {{ final_order.total_price }}</b></span></div>
        </md-content>
      </div>
      <div class="md-layout">
        <md-content class="order-detail__info order-detail__info--centered md-layout-item md-elevation-3">
          <div><span>{{ $translate("orders.table.order_id") }}:</span> <span>{{ final_order.id }}</span></div>
        </md-content>
      </div>

      <p class="md-title">{{ $translate("orders.order_detail.address_title") }}</p>
      <div class="md-layout">
        <div class="md-layout-item md-subheading">{{ $translate("orders.order_edit.shipping_address") }}:</div>
        <div class="md-layout-item md-subheading">{{ $translate("orders.order_edit.billing_address") }}:</div>
      </div>
      <div class="md-layout">
        <md-content class="order-detail__info md-layout-item md-elevation-3">
          <div v-for="[key, value] in shipping_address" :key="`order-shipping-edit-${key}`">
            <span>{{ $translate(`address.${key}`) }}:</span> <span>{{ value }}</span>
          </div>
        </md-content>
        <md-content class="order-detail__info md-layout-item md-elevation-3">
          <div v-for="[key, value] in billing_data" :key="`order-shipping-edit-${key}`">
            <span>{{ $translate(`address.${key}`) }}:</span> <span>{{ value }}</span>
          </div>
        </md-content>
      </div>

      <div v-if="final_order.shipping_lines">
        <div class="md-layout-item md-subheading">{{ $translate("orders.order_detail.shipping_method") }}:</div>
        <div class="md-layout">
          <md-content
            v-for="({ title, country_code, state, initial_price, price, is_custom, type, country, name: location_name, id: location_id }, index) in shipping_lines"
            :key="index"
            class="order-detail__shipping md-layout-item md-elevation-3"
          >
            <div><span>{{ $translate("orders.order_detail.shipping_method_name") }}:</span> <span>{{ title || $translate(`orders.other_method_types.${type}`) }}</span></div>
            <div><span>{{ $translate("orders.order_detail.destination_country") }}:</span> <span>{{ get_country_by_code(country_code || country).name || "Unknown" }}</span></div>
            <div v-if="type"><span>{{ $translate("orders.order_detail.location") }}:</span> <span>{{ location_name }}</span></div>
            <div v-if="type"><span>{{ $translate("orders.order_detail.location_id") }}:</span> <span>{{ location_id }}</span></div>
            <div><span>{{ $translate("orders.order_detail.destination_state") }}:</span> <span>{{ state }}</span></div>
            <div><span>{{ $translate("orders.order_detail.base_price") }}:</span> <span>{{ currency }} {{ initial_price || price }}</span></div>
            <div><span>{{ $translate("orders.order_detail.final_price") }}:</span> <span>{{ currency }} {{ price }}</span></div>
            <div><span>{{ $translate("orders.order_detail.is_custom") }}:</span> <span class="order-detail--large">{{ is_custom ? "✅" : "❌" }}</span></div>
          </md-content>
        </div>
      </div>

      <div v-if="final_order.discount_codes && order.discount_codes.length">
        <p class="md-title">{{ $translate("orders.table.discounts") }}</p>
        <md-table v-model="final_order.discount_codes" md-card class="order-detail__products">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="$translate('orders.discount.code')">{{ item.code }}</md-table-cell>
            <md-table-cell v-if="item.type === 'fixed_amount'" :md-label="$translate('orders.discount.amount')">{{ currency }} {{
              Number(item.translations ? item.translations[final_order.customer_locale].value : item.amount) * -1
            }}</md-table-cell>
            <md-table-cell v-else :md-label="$translate('orders.discount.amount')">{{
              Number(item.translations ? item.translations[final_order.customer_locale].value : item.amount) * -1
            }}%</md-table-cell>
          </md-table-row>
        </md-table>
      </div>

      <p class="md-title">{{ $translate("orders.table.products") }}</p>
      <md-table v-model="final_order.line_items" md-card class="order-detail__products">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell :md-label="$translate('orders.order_detail.product_picture')"><img :src="item.image" alt="Product image"></md-table-cell>
          <md-table-cell :md-label="$translate('orders.order_detail.pruduct_name')">{{ item.title }}</md-table-cell>
          <md-table-cell :md-label="$translate('orders.order_detail.price')">
            {{ currency }} {{ get_correct_translation_value(item, "price", [final_order.customer_locale], false, false, "prices") }} {{ $translate("orders.table.excl_tax") }}
          </md-table-cell>
          <md-table-cell :md-label="$translate('orders.order_detail.quantity')">{{ item.quantity }}</md-table-cell>
          <md-table-cell :md-label="$translate('orders.order_detail.pruduct_variants')">
            <div
              v-for="({ name, value }, index) in item.variant_options"
              v-show="value !== default_variant_title"
              :key="index"
              class="order-detail__variant"
            >
              <span>{{ name }}: </span>{{ value }}
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>


      <p class="md-title">{{ $translate("orders.notes_title") }}</p>
      <div>
        <md-content
          v-for="(value, key) in note"
          :key="key"
          class="order-detail__note md-layout-item md-elevation-3"
        >
          <b>{{ key }}.</b>
          <div>
            {{ value }}
          </div>
          <md-button
            v-if="permissions.finance.sections.orders.module"
            class="md-fab md-raised md-accent"
            @click="remove_note(key)"
          >
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="top">{{ $translate("delete") }}</md-tooltip>
          </md-button>
        </md-content>

        <md-field v-if="permissions.finance.sections.orders.module">
          <label>{{ $translate("orders.add_note") }}</label>
          <md-textarea v-model="note_input"/>
        </md-field>
        <md-button class="md-dense md-raised md-primary" @click="add_note">{{ $translate("add") }}</md-button>
      </div>
    </div>
    <div v-else-if="!final_order.id" class="order-detail__loading">
      <p class="md-title element-loading"/>
      <div class="md-layout">
        <md-content class="order-detail__info md-layout-item md-elevation-3">
          <div><span class="element-loading"/> <span class="element-loading"/></div>
          <div><span class="element-loading"/> <span class="element-loading"/></div>
          <div><span class="element-loading"/> <span class="element-loading"/></div>
          <div><span class="element-loading"/> <span class="element-loading"/></div>
        </md-content>
        <md-content class="order-detail__info md-layout-item md-elevation-3">
          <div><span class="element-loading"/> <span class="element-loading"/></div>
          <div><span class="element-loading"/> <span class="element-loading"/></div>
          <div><span class="element-loading"/> <span class="element-loading"/></div>
          <div><span class="element-loading"/> <span class="element-loading"/></div>
        </md-content>
      </div>

      <p class="md-title element-loading"/>
      <div class="md-layout">
        <div class="md-layout-item md-subheading"><span class="element-loading"/></div>
        <div class="md-layout-item md-subheading"><span class="element-loading"/></div>
      </div>
      <div class="md-layout">
        <md-content class="order-detail__info md-layout-item md-elevation-3">
          <div v-for="(_, ind) in [{},{},{}]" :key="`order-shipping-edit-${ind}`">
            <span class="element-loading"/> <span class="element-loading"/>
          </div>
        </md-content>
        <md-content class="order-detail__info md-layout-item md-elevation-3">
          <div v-for="(_, ind) in [{},{},{}]" :key="`order-shipping-edit-${ind}`">
            <span class="element-loading"/> <span class="element-loading"/>
          </div>
        </md-content>
      </div>
    </div>
    <p v-else-if="!order.id && !order_data" class="md-title">{{ $translate("orders.order_detail.order_not_found") }}</p>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { date_time_format, orders_API_date_format, payment_methods, order_financial_statuses, other_shipping_methods } from "../../../../Shared/constants/other"
import un_bind from "../../../../Shared/methods/un_bind"
import { ADMIN, FINANCE_STORE, imported, shopify, USER_STORE } from "../../../constants/others_constants"
import { order_action_types } from "../../../../Shared/constants/other"
import { UPDATE_IMPORTED_ORDERS, UPDATE_SHOPIFY_ORDERS, UPDATE_ORDERS } from "../../../stores/Admin/finance/constants"
import { FETCH_AND_ASSING_MOMENT_LIBRARY, POST_UPDATED_ORDERS } from "../../../stores/Admin/constants"
import refund_icon from "../../../../Shared/components/icon_components/refund-icon"
import { default_variant_title, order_fulfillment_statuses } from "../../../../../../functions/constans/other"
import { fetch_order } from "../../../../Shared/methods/ajax/endpoints"
import order_statuses from "../../../constants/order_statuses"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import get_country_by_code from "../../../../Shared/methods/get_country_by_code"

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    order_id: {
      type: String,
      required: true
    },
  },
  components: {
    refund_icon,
  },
  data() {
    return {
      order_data: null,
      note_input: "",
      created_date: "",
      order_action_types,
      default_variant_title,
      order_statuses,
      payment_methods,
      order_financial_statuses,
      order_fulfillment_statuses,
      shopify,
      imported
    }
  },
  computed: {
    ...mapState(FINANCE_STORE, ["orders"]),
    ...mapState(USER_STORE, ["permissions"]),
    ...mapState(ADMIN, ["$moment"]),
    show_modal: {
      get() { return this.toggle_order_detail },
      set(val) { this.$emit("toggle-modal", val) }
    },
    currency() {
      return this.final_order.currency
    },
    final_order() {
      return (this.order.id ? this.order : this.order_data) || {}
    },
    shipping_lines() {
      return this.final_order.shipping_lines || []
    },
    total_shipping_price() {
      return Number(this.shipping_lines.reduce((total, { price = 0 }) => Number(price) + total, 0)).toFixed(2)
    },
    total_shipping_discounts() {
      return Number(this.shipping_lines.reduce(
        (total, { initial_price = 0, price = 0 }) => total + (Number(initial_price || price) - Number(price)),
        0
      ).toFixed(2))
    },
    product_discounts() {
      return Number(this.final_order.total_discounts || 0) - this.total_shipping_discounts
    },
    shipping_address() {
      return Object.entries(un_bind({
        ...this.final_order.shipping_address,
        billing_data: undefined,
        first_name: undefined,
        last_name: undefined
      })).filter(address_field => address_field[1])
    },
    billing_data() {
      return Object.entries({
        ...this.final_order.billing_address,
        first_name: undefined,
        last_name: undefined
      }).filter(address_field => address_field[1])
    },
    note() {
      try {
        return JSON.parse(this.final_order.note)
      } catch (error) {
        return {}
      }
    },
    get_formatted_date() {
      if (!this.$moment) return

      return this.$moment(this.order.created_at, orders_API_date_format).format(date_time_format)
    },
    is_order_shopify() {
      return !this.final_order.paypal_data && !this.final_order.payment_method
    },
    is_zasilkovna_order() {
      return this.shipping_lines?.[0]?.type === other_shipping_methods.zasilkovna
    },
  },
  async created() {
    await Promise.all([
      this.fetch_and_assing_moment(),
      this.final_order.id ? null : await this.fetch_order()
    ])
  },
  methods: {
    ...mapActions(FINANCE_STORE, {
      update_imported_orders: UPDATE_IMPORTED_ORDERS,
      update_shopify_orders: UPDATE_SHOPIFY_ORDERS,
      post_updated_orders: POST_UPDATED_ORDERS
    }),
    ...mapActions(ADMIN, {
      fetch_and_assing_moment: FETCH_AND_ASSING_MOMENT_LIBRARY
    }),
    ...mapMutations(FINANCE_STORE, {
      update_store_orders: UPDATE_ORDERS,
    }),
    get_country_by_code,
    get_correct_translation_value,
    async fetch_order() {
      const { data } = await fetch_order(this.order_id)

      this.update_store_orders([[...(this.orders || []), data]])
    },
    update_orders(type) {
      this.post_updated_orders([
        [this.final_order],
        { [this.final_order.id]: this.final_order.fulfillments },
        type,
        this.is_order_shopify ? shopify : imported
      ])
    },
    update_order_note(note) {
      this.is_order_shopify ?
        this.update_shopify_orders([
          this.final_order,
          order_action_types.edit,
          {
            order: {
              id: this.final_order.id,
              note: JSON.stringify(note)
            }
          }
        ]) :
        this.update_imported_orders([{
          ...this.final_order,
          note: JSON.stringify(note)
        }])
    },
    initialize_order_edit(editing_tracking) {
      this.fulfillments = this.selected_orders.reduce((tot, { fulfillments = [], id }) => ({
        ...tot,
        [id]: fulfillments
      }), {})
      this[`show_${editing_tracking ? "tracking" : "orders"}_edit_modal`] = true
    },
    remove_note(note_id) {
      const final_note = { ...this.note }
      delete final_note[note_id]

      this.update_order_note(Object.values(final_note).reduce((tot, value, index) => ({
        ...tot,
        [index + 1]: value
      }), {}))
    },
    add_note() {
      this.update_order_note({
        ...this.note,
        [Object.keys(this.note).length + 1]: this.note_input
      })
      this.note_input = ""
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/element_loading.scss";

  .order-detail {
    padding-bottom: $double-default-size;

    &__loading {
      .order-detail__info div {
        margin-bottom: $half-default-size;
      }
      .md-title {
        height: 30px;
        width: 50%;
        max-width: 300px;
      }
    }

    &--large {
      font-size: 18px;
      font-weight: bold;
    }

    &--capitalize {
      text-transform: uppercase;
    }

    &__products img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    &__variant span {
      font-weight: bold;
    }

    &__shipping {
      padding: $half-default-size $default-size !important;

      div {
        display: inline-block;
        width: calc(50% - 3px);

        span:first-child {
          font-weight: bold;
        }
      }
    }

    .md-headline {
      margin-top: $default-size;
    }

    .md-title {
      margin: $default-size 0 $half-default-size;
      line-height: $double-default-size;
    }

    &__info {
      padding: $default-size $half-default-size !important;

      span {
        display: inline-block;
        width: calc(50% - 3px);
        word-break: break-word;
        padding: 0 5px;
        vertical-align: top;
        font-size: 15px;

        &:first-child {
          width: calc(50% - 3px);
          text-align: right;
          font-weight: bold;
        }
      }

      &--centered {
        text-align: center;

        span {
          width: auto !important;
        }
      }
    }

    .md-content {
      margin: $half-default-size;
    }

    .md-has-textarea {
      width: auto;
      margin: $half-default-size $default-size;
    }

    .md-dense {
      margin-left: 0;
    }

    .md-speed-dial {
      float: right;
      margin-right: $half-default-size;

      &-target {
        margin: 0;
      }
    }

    .order-detail__note {
      display: grid;
      grid-template-columns: $double-default-size calc(100% - #{$double-default-size} * 3) $double-default-size;
      column-gap: $default-size;
      padding: $half-default-size $default-size;
      margin: $half-default-size $default-size;

      & > div {
        padding-right: $default-size;
      }

      .md-fab {
        width: $double-default-size;
        height: $double-default-size;
        margin: 0;
      }
    }
  }
</style>
